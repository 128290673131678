/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { memo, useMemo } from 'react'

import { flex, flexRow } from '@bonitour/components'

import { EXPERIENCE_VACANCY_TYPE_OPACITY } from 'constants/bookingMap'

import { hidden, positionRelative } from 'assets/styles/global'
import { ActivityRow, MinifiedCombinedExperienceCell, gap } from './combinedExperienceSummaryCellGroup.styles'
import { CombinedExperiencePaxSelector } from 'domains/CombinedExperience/components/CombinedExperiencePaxSelector/CombinedExperiencePaxSelector'
import { minutesToText, timeToMinutes } from 'utils/time'
import { identity } from '@bonitour/common-functions'

const ExperienceSummaryCellGroup = ({
  getSelectedTotals = identity,
  getBlockedSummaryAttrs = identity,
  onSelectActivity = identity,
  activityData = {},
  dateColumns = [],
  date,
  activitiesFees = [],
  activityIndex = 0,
  limitOfDay = 0,
  children
}) => {
  const {
    vacancies = {},
    reservations = {},
    activityId = '',
    color = '',
    hour = 'without-hour',
    duration = ''
  } = activityData || {}

  const {
    total: totalAvailable = 0
  } = vacancies || {}

  const { total: reservationsTotal } = reservations

  const {
    selectedTotals,
    isFirstDateOfHour
  } = useMemo(() => ({
    selectedTotals: getSelectedTotals(activityId, hour, date),
    isFirstDateOfHour: dateColumns[0] === date
  }), [activityId, date, dateColumns, getSelectedTotals, hour])

  const [isBlockedSummary] = useMemo(() => getBlockedSummaryAttrs(activityData), [activityData, getBlockedSummaryAttrs])

  return (
    <ActivityRow>
      {isFirstDateOfHour
        ? (<>
          <MinifiedCombinedExperienceCell
            className='color-as-bg-color absolute-on-left text-bold'
            bgColor={color}
          >
            {minutesToText(timeToMinutes(duration))}
          </MinifiedCombinedExperienceCell>
        </>)
        : null}
      <div
        css={[flex, flexRow, positionRelative, gap, isBlockedSummary && hidden]}>
        <CombinedExperiencePaxSelector
          activitiesFees={activitiesFees}
          hasActivitiesFees={activitiesFees.length > 0}
          activityIndex={activityIndex}
          hour={hour}
          duration={duration}
          color={color}
          limitOfDay={limitOfDay}
          date={date}
          onSelectActivity={onSelectActivity}
        >
          <MinifiedCombinedExperienceCell
            bgColor={color}
            className={'draw-text-shadow-light clickable hoverable can-margin'}
            css={[isBlockedSummary && hidden]}
            bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.totalAvailable}
          >
            <span className='left'>{totalAvailable}</span>
          </MinifiedCombinedExperienceCell>
        </CombinedExperiencePaxSelector>
        {selectedTotals
          ? (
            <MinifiedCombinedExperienceCell
              bgColor={color}
              className='color-as-bg-color border-as-bg-color absolute-on-middle'
              css={[isBlockedSummary && hidden]}
            >
              <div>{selectedTotals}</div>
            </MinifiedCombinedExperienceCell>
          )
          : null}
        <MinifiedCombinedExperienceCell
          bgColor={color}
          className={'draw-text-shadow hoverable can-margin'}
          css={[isBlockedSummary && hidden]}
          bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.reservationsTotal}
        >
          <span className='right'>{reservationsTotal}</span>
        </MinifiedCombinedExperienceCell>
      </div>
      {isBlockedSummary ? children : ''}
    </ActivityRow>
  )
}

export const CombinedExperienceSummaryCellGroup = memo(ExperienceSummaryCellGroup)
