/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useCombinedExperienceForm } from '../Config/hooks/useCombinedExperienceForm'

const BookingMapInfoCtx = createContext()

export const BookingMapInfoProvider = ({ children }) => {
  const [feePrices, setFeePrices] = useState(null)

  const {
    onSelectActivity,
    resetForm,
    values: {
      selectedActivities
    }
  } = useCombinedExperienceForm()

  const calculatedActivitiesFeesPrices = useMemo(() => {
    if (!Array.isArray(feePrices) || !Array.isArray(selectedActivities)) return {}

    const feePricesMap = new Map(feePrices.map(({ id, prices }) => [id, prices]))

    const experiencesIds = selectedActivities.map(({ serviceId }) => serviceId)

    const processedIds = new Set()

    return experiencesIds.reduce((acc, id) => {
      if (processedIds.has(id)) return acc

      const { total, quantity } = selectedActivities
        .filter(({ serviceId }) => serviceId === id)
        .reduce((acc, curr) => {
          const { feeName, quantity, date } = curr

          const feeDates = feePricesMap.get(id)

          if (!feeDates) return acc

          const feeData = feeDates?.[date]?.find(({ name }) => name === feeName)

          if (!feeData) return acc

          acc.total += Number(feeData.pricing) * quantity
          acc.quantity += quantity

          return acc
        }, { total: 0, quantity: 0 })

      acc[id] = {
        quantity,
        total
      }

      processedIds.add(id)

      return acc
    }, {})
  }, [feePrices, selectedActivities])

  const loadFeePrices = useCallback(({ initialFeePrices = null }) => {
    if (!initialFeePrices || !Array.isArray(initialFeePrices)) return
    setFeePrices(initialFeePrices)
  }, [])

  const value = useMemo(() => ({
    selectedActivities,
    onSelectActivity,
    activitiesFeesPrices: calculatedActivitiesFeesPrices,
    loadFeePrices,
    resetForm
  }), [selectedActivities, onSelectActivity, calculatedActivitiesFeesPrices, loadFeePrices, resetForm])

  return <BookingMapInfoCtx.Provider value={value}>{children}</BookingMapInfoCtx.Provider>
}

export const useBookingMapInfo = () => {
  const context = useContext(BookingMapInfoCtx)

  if (!context) {
    console.warn('useBookingMapInfo must be used within a BookingMapInfoProvider')
  }

  return context || {}
}
