/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { participantCount, priceAndParticipants, serviceCombinedExperienceCardInfo } from '../ServiceCombinedExperienceList.style'
import { formatMoney } from '@bonitour/common-functions'

export const ServiceCombinedExperienceCardInfo = ({
  experiencePaxInfo = {},
  index = 0
}) => {
  const { quantity, total } = experiencePaxInfo
  return (
    <div css={serviceCombinedExperienceCardInfo}>
      <div css={priceAndParticipants}>
        <div css={participantCount(index)}>
          <span>
            {quantity}
          </span>
        </div>
        <span>{formatMoney(total)}</span>
      </div>
    </div>
  )
}
