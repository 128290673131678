/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'

import { chainRowStyle, chainIconStyle, chainStyle, chainContainerStyle } from './ReservationTimelineChain.style'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useMemo } from 'react'
import { ReservationTimelineIcon } from './ReservationTimelineIcon'
import { toTitleCase } from './helpers/toTitleCase'

dayjs.extend(utc)

/**
 * @type {React.FC<{chainLength?: number, customKey?: string}>}
*/
export const Chain = ({ chainLength = 7, customKey = '' }) => {
  return <>
    {Array.from({ length: chainLength }).map((_, idx) => (
      <div
        css={[chainStyle]}
        className='chain'
        key={`timeline-chain-#${idx}-${customKey}`}
      />
    ))}
  </>
}

/**
 * @type {React.FC<{children: JSX.Element, chainLength?: number, customChainsKey: string}>}
 */
export const ChainContainer = ({
  children,
  chainLength = 7
}) => {
  return <div css={chainContainerStyle} >
    {children}
    <div css={chainStyle} style={{ height: `${chainLength * 2}rem` }}/>
  </div>
}

/**
 * @type {React.FC<{date: string, type: string, children: JSX.Element, chainLength?: number}>}
*/
export const ReservationTimelineChain = ({
  date,
  type,
  register,
  children,
  chainLength = 7
}) => {
  const dayJsDate = useMemo(() => dayjs(date), [date])

  const dayWithMonth = useMemo(
    () => {
      const day = dayJsDate.date()
      const month = toTitleCase(
        dayJsDate
          .toDate()
          .toLocaleDateString('pt-BR', { month: 'short' })
      )

      const canShowYear = dayJsDate.year() !== dayjs().year()

      const dateLabel = canShowYear
        ? `${day} de ${month} de ${dayJsDate.year()}`
        : `${day} de ${month}`

      return `${dateLabel} às ${dayJsDate.format('HH:mm')}`
    }
    , [dayJsDate]
  )

  const marginBottom = useMemo(() => `${Math.max((chainLength * 2) - 5.5, 0.5)}rem`, [chainLength])

  return (
    <div css={chainRowStyle} className='full-chain' style={{ marginBottom }}>
      <p>
        <span className='register-by'>Realizado por</span>
        <br/>
        {register}
        <br />
        <span className='date-time'>
          {dayWithMonth}
        </span>
      </p>
      <ChainContainer
        chainLength={chainLength}
        customChainsKey={date}
      >
        <div css={chainIconStyle}>
          <ReservationTimelineIcon mode={type} />
        </div>
      </ChainContainer>
      <section>
        {children}
      </section>
    </div>
  )
}
