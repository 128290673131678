import { string, object, array } from 'yup'
import { periodsSchemaBuilder } from 'containers/Periods/Periods.schema'
import { feeByDemandSchema } from './FeeByDemand/FeeByDemand.schema'
import { feePaxTypeSchema } from './FeePaxType/FeePaxType.schema'
import { head, tail } from '@bonitour/common-functions'
import { FEE_TYPES, PAX_TYPE_NEED_VALIDATION } from 'constants/fees'

export const feeSchema = activityMinAge => object().shape({
  feeBasic: object().shape({
    name: string().required('O nome da tarifa é obrigatório'),
    price: string().required('O preço da tarifa é obrigatório')
  }),
  periodList: array().of(object(periodsSchemaBuilder(false))),
  feesByDemands: array().of(object(feeByDemandSchema)),
  feePaxTypes: array().of(object(feePaxTypeSchema(activityMinAge)))
})

const mapAges = (ages) => ages.reduce((acc, { name = '', minAge, maxAge }) => {
  return acc.concat([[minAge, name], [maxAge, name]])
}, []).sort(([a], [b]) => Number(a) - Number(b))

export const validateFeeTypes = (activityMinAge, feePaxTypes) => {
  const agesToValidate = feePaxTypes.filter(({ name }) => PAX_TYPE_NEED_VALIDATION.includes(name))

  const singleFee = feePaxTypes.filter(({ name }) => name === FEE_TYPES?.SINGLE)

  if (singleFee.length && activityMinAge > 0 && Number(activityMinAge) !== Number(head(singleFee).minAge)) {
    return { minAgeError: true }
  }

  const privateFee = feePaxTypes.filter(({ name }) => name === FEE_TYPES?.PRIVATE)

  if (privateFee.length && activityMinAge > 0 && Number(activityMinAge) !== Number(head(privateFee).minAge)) {
    return { minAgeError: true }
  }

  if (!agesToValidate.length) {
    return
  }

  const allAgesMapping = mapAges(feePaxTypes)
  const filteredAgesMapping = mapAges(agesToValidate)

  if (activityMinAge > 0 && Number(activityMinAge) !== Number(head(head(allAgesMapping)))) {
    return { minAgeError: true }
  }

  return filteredAgesMapping.reduce((acc, [age, name], index) => {
    if (index > 0) {
      const previous = filteredAgesMapping[index - 1]
      const next = filteredAgesMapping[index + 1]
      acc.conflictError = acc.conflictError || (next ? !(tail(previous) === name || tail(next) === name) : tail(previous) !== name)
      acc.gapError = acc.gapError || (next ? !((Number(age) - Number(head(previous)) === 1) || (Number(head(next)) - (Number(age)) === 1)) : false)
    }
    return acc
  }, {})
}
