/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useMemo } from 'react'

import { Controller } from 'react-hook-form'

import { InvoiceDeadline } from './InvoiceDeadline'
import { PartnershipGroupError } from '../components'
import { H3, Card, ToggleInputGroup, Select, P, RadioInputGroup, RadioGroup } from '@bonitour/components'

import { mapDaysOptions, workingDaysOptions } from 'constants/weekDaysOptions'
import { seasonalPeriodOptions } from 'constants/seasonalPeriodOptions'

import { cardStyle, marginSide, grayColor, boldText, line, marginTop30, marginTop20, marginBottom20, selectStyle100, selectStyle30, selectMarginDisabled } from './InvoicePolicy.style'
import { hidden } from 'assets/styles/global'

export const InvoicePolicy = ({
  formUtils = {},
  invoicePolicy = {},
  invoicePolicyErrors = {},
  isFormDisabled = false,
  isGroupEditable = false,
  isLoading = false,
  submitCount = 0
}) => {
  const {
    onChange: emitChangeEvent,
    control: formControl,
    trigger: emitTrigger
  } = formUtils

  const {
    invoiced = false,
    deadline = 0,
    deadlineType = '',
    closingPeriod = '',
    invoice: {
      noShow = false
    } = {}
  } = invoicePolicy

  const isFieldEditable = useMemo(() => !isGroupEditable, [isGroupEditable])

  const isInvoicedSelected = useMemo(() => {
    const isBoolean = typeof invoiced === 'boolean'
    return isBoolean ? invoiced : invoiced === 'true'
  }, [invoiced])

  const toggleNoShow = useCallback(() => emitChangeEvent('invoicePolicy.invoice.noShow')(!noShow), [emitChangeEvent, noShow])

  const daysOptions = useMemo(() => mapDaysOptions[closingPeriod || ''] || [], [closingPeriod])

  const {
    closingPeriodErrorControl,
    closingDayErrorControl,
    paymentDayErrorControl
  } = useMemo(() => {
    const getHasError = (error) => Boolean(error && error?.message)
    return {
      closingPeriodErrorControl: getHasError(invoicePolicyErrors?.closingPeriod) ? 'has-error' : '',
      closingDayErrorControl: getHasError(invoicePolicyErrors?.closingDay) ? 'has-error' : '',
      paymentDayErrorControl: getHasError(invoicePolicyErrors?.paymentDay) ? 'has-error' : ''
    }
  }, [invoicePolicyErrors])

  const onChangeInvoice = useCallback((setterFn, arg) => {
    setterFn(arg === 'true')
    if (submitCount > 0) {
      emitTrigger('invoicePolicy.deadline')
    }
  }, [emitTrigger, submitCount])

  const getBoolStringified = useCallback((value) => {
    if (typeof value === 'boolean') {
      return value.toString()
    }
    return value
  }, [])

  return (
    <div css={grayColor}>
      <H3 css={marginBottom20}>Fechamento</H3>
      <div css={line}>
        <div className={[closingPeriodErrorControl || closingDayErrorControl, !closingPeriodErrorControl && 'no-has-error'].join(' ')}>
          <span>O fechamento da fatura será</span>
          <Controller
            name='invoicePolicy.closingPeriod'
            control={formControl}
            render={({
              field: { onChange, name, value }
            }) => <PartnershipGroupError error={invoicePolicyErrors?.closingPeriod}>
              <Select
                options={seasonalPeriodOptions}
                css={[selectStyle100, marginSide]}
                value={value}
                disabled={isFieldEditable || isLoading}
                error={invoicePolicyErrors?.closingPeriod}
                onChange={onChange}
                name={name}
                customCss={[selectMarginDisabled]}
              />
            </PartnershipGroupError>}
          />
        </div>
        <div className={closingDayErrorControl}>
          <span>na(o)</span>
          <Controller
            name='invoicePolicy.closingDay'
            control={formControl}
            render={({
              field: { onChange, value }
            }) => <PartnershipGroupError error={invoicePolicyErrors?.closingDay}>
              <Select
                css={[selectStyle100, marginSide]}
                options={daysOptions}
                value={value}
                disabled={isFieldEditable || isLoading}
                error={invoicePolicyErrors?.closingDay}
                onChange={onChange}
                customCss={[selectMarginDisabled]}
              />
            </PartnershipGroupError>}
          />
        </div>
        <div className={paymentDayErrorControl}>
          <span>e o pagamento será em até</span>
          <Controller
            name='invoicePolicy.paymentDay'
            control={formControl}
            render={({
              field: { onChange, value, name }
            }) => <PartnershipGroupError error={invoicePolicyErrors?.paymentDay}>
              <Select
                css={[selectStyle30, marginSide]}
                options={workingDaysOptions}
                value={value}
                disabled={isFieldEditable || isLoading}
                error={invoicePolicyErrors?.paymentDay}
                onChange={onChange}
                name={name}
                customCss={[selectMarginDisabled]}
              />
            </PartnershipGroupError>}
          />
          <span>dias úteis</span>
        </div>
      </div>

      <H3 css={[marginTop20, marginBottom20]}>Deadline</H3>

      <InvoiceDeadline
        deadline={deadline}
        deadlineType={deadlineType}
        isFormDisabled={isFieldEditable}
        isLoading={isLoading}
        invoicePolicyErrors={invoicePolicyErrors}
        formUtils={formUtils}
        submitCount={submitCount}
      />

      <H3 css={[marginTop30, marginBottom20]}>Pagamento</H3>

      <Controller
        name='invoicePolicy.invoiced'
        control={formControl}
        render={({
          field: { onChange, value, name }
        }) => <RadioGroup
          name={name}
          value={getBoolStringified(value)}
          onChange={(selectedValue) => onChangeInvoice(onChange, selectedValue)}
          onChangeReceivesEvent
          disabled={isFieldEditable}
          customCss={[isFormDisabled && hidden]}
        >
          <RadioInputGroup value={'false'} onChangeReceivesValue disabled={isLoading} >Antecipado</RadioInputGroup>
          <RadioInputGroup value={'true'} onChangeReceivesValue disabled={isLoading}>Faturado</RadioInputGroup>
        </RadioGroup>}
      />

      <P css={marginTop20}>
        <span css={[boldText, !isFormDisabled && hidden]}>
          {'Tipo de pagamento: '}
        </span>
        {isInvoicedSelected ? 'Faturado' : ''}
      </P>

      <Card css={[cardStyle, !isInvoicedSelected && hidden]}>
        <H3 css={marginBottom20}>Garantia</H3>

        <ToggleInputGroup
          checked={noShow}
          onChange={toggleNoShow}
          disabled={isFieldEditable || isLoading}
          canEmitToggleEventOfRow={true}
        >
          Terá garantia de no-show
        </ToggleInputGroup>
      </Card>
    </div>
  )
}
