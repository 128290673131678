import { TRANSPORT_TYPE } from 'constants/activityTypes'
import { useEffect, useRef, useState } from 'react'
import { TransportService } from 'services/Transport/Service'

export const useTransport = (activity) => {
  const [transport, setTransport] = useState(null)
  const requestId = useRef(0)
  useEffect(() => {
    const isTransport = activity?.id && activity?.type === TRANSPORT_TYPE
    if (isTransport) {
      if (transport?.transportBase?.transportId !== activity.id) {
        const currentRequest = ++requestId.current
        TransportService.get(activity.id).then((data) => {
          if (currentRequest === requestId.current) {
            setTransport(data)
          }
        })
      }
    } else if (transport) {
      ++requestId.current
      setTransport(null)
    }
  }, [activity, transport])

  return transport
}
