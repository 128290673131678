import { colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const popoverItem = css`
  width: 1.25rem;
  display: flex;
  align-items: flex-start;
`

export const popoverItemNameAndAge = css`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 3.75rem;
  color: ${colors.gray4};
`

export const priceStyle = css`
  text-align: center;
  width: auto;
  min-width: 3.125rem;
`

export const containerCounter = css`
  border: 1px solid ${colors.gray7};
  border-radius: var(--round-element-radius);
  align-items: center;
  background-color: ${colors.gray13};
  width: 6.438rem;
  max-width: 100%;

  button {
    background-color: ${colors.gray13};
    color: black;

    &::after {
      color: ${colors.gray5};
      font-weight: lighter;
    }
  }
`

export const popoverContainer = css`
  width: 22rem;
  flex-direction: column;
  cursor: default;
  user-select: none;
  color: ${colors.gray4};
`

export const emphasisInfo = css`
  font-size: 1.10rem;
  font-weight: 500;
`

export const headerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const timeStyle = css`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: center;
  .bold {
    font-weight: bolder;
  }
`

export const counterButtons = css`
  background-color: ${colors.gray13};
`

export const durationStyle = css`
  display: flex;
  padding: 0.625rem;
  i {
    margin-right: 0.15rem;
  }
`

export const cellPrice = css`
  color: ${colors.gray4};
  padding: 0.625rem;
`

export const counterWrapper = css`
  border: 1px solid ${colors.gray7};
  border-radius: var(--round-element-radius);
  align-items: center;
  background-color: ${colors.gray13};
  width: 6.438rem;
  max-width: 100%;
  button {
    background-color: ${colors.gray13};
    &::after {
      color: ${colors.gray5};
      font-weight: lighter;
    }
  }
`

export const feeRowContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 0 0.75rem 0;
`

export const feeRow = css`
  display: flex;
  width: calc(100% - 0.65rem);
  justify-content: space-between;

  .fee-details {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    span:last-of-type {
      margin-top: 0.15rem;
    }
  }

  section {
    display: inline-flex;
    gap: 0.5rem;

    span {
      display: inline-flex;
      align-items: center;
    }
  }
`
