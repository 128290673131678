/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, colors, flexColumn, Card, BREAK_POINTS } from '@bonitour/components'
import { AddLabel } from 'components/AddLabel'
import { TarifPeriodRow } from './TarifPeriodRow'
import { useFieldArray } from 'react-hook-form'
import { memo, useCallback } from 'react'
import { SafeDate } from '@binamik/js-providers'

const customCardPadding = css`
  padding: 10px 20px;
  margin-bottom: 0;
  border: none;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    position: relative;
  }
`

const darkBackgroundCard = css`
  background: ${colors.gray11};
`

const marginBottom20 = marginBottom(20)
const marginBottom30 = marginBottom(30)

const emptyActivityVacancyPeriod = {
  name: '',
  startDate: new SafeDate().toStartOf('day').jsDate,
  endDate: new SafeDate().toStartOf('day').jsDate
}

export const TarifPeriods = memo(({
  label = 'Períodos',
  placeholder = '',
  errors = [],
  register,
  control
}) => {
  const { fields: periodList, append, remove } = useFieldArray({
    control,
    name: 'periodList'
  })

  const addPeriodToList = useCallback(
    () => append(emptyActivityVacancyPeriod)
    , [append]
  )

  const removePeriodFromList = useCallback(
    (index) => () => remove(index)
    , [remove])

  return (
    <>
      <H3 css={marginBottom20}>{label}</H3>
      {periodList?.map((_period, index) => (
        <Card css={[customCardPadding, index % 2 === 0 && darkBackgroundCard, flexColumn]} key={`fee-period-${index}`}>
          <TarifPeriodRow
            index={index}
            placeholder={placeholder}
            errors={errors[index]}
            register={register}
            control={control}
            removePeriodFromList={removePeriodFromList(index)}
          />
        </Card>
      ))}
      <AddLabel css={marginBottom30} onClick={addPeriodToList}>
        Novo período
      </AddLabel>
    </>
  )
})
