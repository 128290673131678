/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, ToggleInputGroup } from '@bonitour/components'
import { AddLabel } from 'components/AddLabel'
import { FeeByDemandRow } from './FeeByDemandRow'
import { memo, useCallback, useMemo } from 'react'
import { useFieldArray } from 'react-hook-form'

const marginBottom20 = marginBottom(20)

const emptyFeeByDemand = { limit: 0, valueModificator: 0, operator: 'increase' }

export const FeeByDemandForm = memo(({
  feePrice = 0,
  feesByDemandErrors = [],
  control
}) => {
  const { fields: feesByDemands, append, remove, update, replace } = useFieldArray({
    control,
    name: 'feesByDemands'
  })

  const hasFeesByDemand = useMemo(() => feesByDemands?.length > 0, [feesByDemands?.length])

  const handleAddFeeByDemand = useCallback(
    () => append(emptyFeeByDemand),
    [append]
  )

  const handleRemoveFeeByDemand = useCallback(
    (index) => () => remove(index),
    [remove]
  )

  const toggleDemandFee = useCallback(
    () => (hasFeesByDemand ? replace([]) : handleAddFeeByDemand())
    , [handleAddFeeByDemand, hasFeesByDemand, replace]
  )

  return (
    <>
      <H3 css={marginBottom20}>Tarifário por demanda</H3>
      <ToggleInputGroup id='demand-fee' checked={hasFeesByDemand} onChange={toggleDemandFee}>
        Utilizar tarifário por demanda
      </ToggleInputGroup>
      <div css={marginBottom20}>
        {hasFeesByDemand && (
          <>
            {feesByDemands?.map((feeByDemandItem, index) => (
              <FeeByDemandRow
                key={`fee-by-demand-${index}`}
                index={index}
                feePrice={feePrice}
                feeByDemandItem={feeByDemandItem}
                errors={feesByDemandErrors[index]}
                handleRemoveFeeByDemand={handleRemoveFeeByDemand(index)}
                control={control}
                update={update}
              />
            ))}
            <AddLabel onClick={handleAddFeeByDemand}>Nova tarifa</AddLabel>
          </>
        )}
      </div>
    </>
  )
})
