import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ReservationMapService } from 'services/ReservationMap/Service'

import { useActivity } from 'contexts/Activity'

import { useDebounce } from '@bonitour/app-functions'
import { useToast } from '@bonitour/components'
import { head, tail } from '@bonitour/common-functions'

import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { colorCycle } from 'constants/combinedExperience'

const MAP_INTERVAL = 60000

export const useMapSubscriber = (dateColumns, interval = true) => {
  const debouncedDates = useDebounce(dateColumns, 750)
  const { companyActivities = [], partnerActivities = [], activity = {} } = useActivity()

  const { id: serviceId, companyId, experiences = [] } = activity

  const {
    add: addToast
  } = useToast()

  const isCombinedExperience = useMemo(() => activity?.type === COMBINED_EXPERIENCE_TYPE, [activity])

  const experienceServices = useMemo(() => {
    if (!isCombinedExperience || !activity) return null
    return experiences.map(({ serviceId }) => serviceId)
  }, [activity, experiences, isCombinedExperience])

  const fetchServiceListData = useCallback(async () => {
    const promises = experienceServices.map((serviceId, serviceIndex) => ReservationMapService.getMap({
      serviceId,
      companyId,
      startDate: head(debouncedDates),
      endDate: tail(debouncedDates)
    })
      .then((response) => {
        const { duration = '', name = '', image = null } = [partnerActivities, companyActivities]
          .flat()
          .find(({ id }) => id === serviceId)

        return { ...response, id: serviceId, color: colorCycle[serviceIndex % colorCycle.length], duration, name, image }
      }))

    return Promise.all(promises)
  }, [companyActivities, companyId, debouncedDates, experienceServices, partnerActivities])

  const {
    data: vacanciesData,
    isLoading,
    isFetching,
    isFetchedAfterMount
  } = useQuery({
    refetchInterval: interval ? MAP_INTERVAL : null,
    queryKey: ['reservationMap', debouncedDates, serviceId, companyId],
    queryFn: () => {
      if (!serviceId || !companyId || !debouncedDates.length) {
        return Promise.resolve({})
      }

      if (isCombinedExperience) {
        return fetchServiceListData()
      }

      return ReservationMapService.getMap({
        serviceId,
        companyId,
        startDate: head(debouncedDates),
        endDate: tail(debouncedDates)
      })
    },
    onError: () => {
      addToast('Houve um problema ao carregar as vagas disponíveis')
    }
  })

  const {
    registries,
    prices
  } = useMemo(() => ({
    registries: isCombinedExperience ? [] : vacanciesData?.registries || [],
    prices: isCombinedExperience ? [] : vacanciesData?.prices || []
  }), [isCombinedExperience, vacanciesData])

  const activitiesColors = useMemo(() => {
    if (isLoading || !isCombinedExperience || !Array.isArray(vacanciesData)) return []
    return vacanciesData.map(({ color }) => color)
  }, [isCombinedExperience, isLoading, vacanciesData])

  return {
    registries,
    prices,
    isLoading: isLoading || isFetching,
    activitiesRegistryAndPrice: isCombinedExperience ? vacanciesData : null,
    activitiesColors,
    experienceServices,
    isFirstLoading: isLoading && !isFetchedAfterMount,
    debouncedDates
  }
}
