import { date, string } from 'yup'
import dayjs from 'dayjs'
import { SafeDate } from '@binamik/js-providers'

const startDateMustBeLessThanOrEqualEndDate = (
  value,
  ctx
) => {
  if (!value || !ctx?.parent?.endDate) {
    return true
  }
  const endDate = new SafeDate(ctx.parent.endDate).jsDate
  const startDate = new SafeDate(value)

  return !startDate.isAfter(endDate)
}

export const endDateMustBeAfterOrEqualStartDate = (
  value,
  ctx
) => {
  if (!value || !ctx?.parent?.startDate) {
    return true
  }

  const startDate = new SafeDate(ctx?.parent?.startDate).jsDate
  const endDate = new SafeDate(value)

  return !endDate.isBefore(startDate)
}

export const periodsSchemaBuilder = (requireAfterToday = false) => ({
  name: string().required('O nome do período é obrigatório'),
  startDate: date()
    .min(dayjs(requireAfterToday ? undefined : 0).startOf('day').toDate(), 'A data inicial não pode ser menor que a data atual')
    .test(
      'startDateMustBeLessThanOrEqualEndDate',
      'A data inicial deve ser menor ou igual a data final',
      startDateMustBeLessThanOrEqualEndDate
    )
    .required('A data inicial é obrigatória'),
  endDate: date()
    .required('A data final é obrigatória')
    .test(
      'endDateMustBeAfterOrEqualStartDate',
      'A data final deve ser maior ou igual a data inicial',
      endDateMustBeAfterOrEqualStartDate
    )
    .test('valid-date-range', 'O intervalo total de datas deve ser menor que 2 anos', (endDate, ctx) => {
      const { startDate } = ctx.parent

      const daysDifference = Math.abs(
        dayjs(endDate).startOf('day').diff(
          dayjs(startDate).startOf('day'),
          'day',
          true
        ))

      return daysDifference < (365 * 2)
    })
})
