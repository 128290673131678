/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { useCallback, useMemo } from 'react'

import { useBookingMapInfo } from 'domains/Booking/Map/contexts/useBookingMapInfo'

import { ServiceCombinedExperienceCardInfo, ServiceCombinedExperienceHeader } from './components'

import {
  serviceCombinedExperienceCard,
  ServiceCombinedExperienceListContainer
} from './ServiceCombinedExperienceList.style'

/**
* ServiceCombinedExperienceList component renders a list of combined experiences.
*
* @param {Object} props - The component props.
* @param {Array} props.experiences - The list of experiences.
* @param {Array} props.customCss - Custom CSS styles.
* @param {string} props.color - The color theme.
* @param {boolean} props.canShowTotals - Flag to show totals.
* @returns {JSX.Element} The rendered component.
*/
export const ServiceCombinedExperienceList = ({
  experiences = [],
  customCss = [],
  color = '',
  canShowTotals = false
}) => {
  const {
    activitiesFeesPrices = {}
  } = useBookingMapInfo()

  const hasSomeActivitySelected = useMemo(() => Object.keys(activitiesFeesPrices).length, [activitiesFeesPrices])

  const getHasPrice = useCallback(({ serviceId }) => {
    const activityFeeData = activitiesFeesPrices?.[serviceId]
    const hasActivityOnFees = activityFeeData
    const hasQuantity = activityFeeData?.total > 0
    return canShowTotals && hasActivityOnFees && hasQuantity
  }, [activitiesFeesPrices, canShowTotals])

  return (
    <ServiceCombinedExperienceListContainer css={customCss}>
      {experiences.map((experience, index) => (
        <div
          css={serviceCombinedExperienceCard}
          key={`combined-experience-card-${experience.id}-${index}`}
          className={canShowTotals && !(getHasPrice({ serviceId: experience.serviceId }) && hasSomeActivitySelected) ? 'fake-has-price-size' : ''}
        >
          <ServiceCombinedExperienceHeader
            color={color}
            experienceInfo={experience}
            index={index}
          />
          {getHasPrice({ serviceId: experience.serviceId })
            ? (
              <ServiceCombinedExperienceCardInfo
                experiencePaxInfo={activitiesFeesPrices[experience.serviceId]}
                index={index}
              />
            )
            : null}
        </div>
      ))}
    </ServiceCombinedExperienceListContainer>
  )
}
