import { metaModel } from './meta'
import moment from 'moment'

/* eslint-disable camelcase */
export const SaleModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      vendor: vendor_id,
      seller: seller_id,
      status: state,
      saleDateStart: initial_sale_date,
      saleDateEnd: final_sale_date,
      tourDateStart: initial_date,
      tourDateEnd: final_date,
      activity: service_id,
      sellingChannel: selling_channel,
      salesResponsibleId: sales_responsible_id,
      userId: user_id,
      countries = [],
      states = [],
      cities = []
    } = filters

    const hasAddressData = countries.length || states.length || cities.length

    const meta = metaModel(pagination)

    const searchParams = {
      vendor_id,
      seller_id,
      state,
      initial_sale_date: initial_sale_date ? moment(initial_sale_date).format('YYYY-MM-DD') : undefined,
      final_sale_date: final_sale_date ? moment(final_sale_date).format('YYYY-MM-DD') : undefined,
      initial_date: initial_date ? moment(initial_date).format('YYYY-MM-DD') : undefined,
      final_date: final_date ? moment(final_date).format('YYYY-MM-DD') : undefined,
      service_id,
      selling_channel,
      sales_responsible_id,
      user_id,
      address: hasAddressData
        ? {
          country_id: countries,
          state_id: states,
          city_id: cities
        }
        : {}
    }

    return { searchParams, pagination: meta }
  },
  csvExport: ({ filters = {} }) => {
    const {
      vendor: vendor_id,
      seller: seller_id,
      status: state,
      saleDateStart: initial_sale_date,
      saleDateEnd: final_sale_date,
      tourDateStart: initial_date,
      tourDateEnd: final_date,
      activity: service_id,
      sellingChannel: selling_channel,
      wantedFields: wanted_fields,
      userId: user_id
    } = filters

    const searchParams = {
      vendor_id,
      seller_id,
      state,
      initial_sale_date: initial_sale_date ? moment(initial_sale_date).format('YYYY-MM-DD') : undefined,
      final_sale_date: final_sale_date ? moment(final_sale_date).format('YYYY-MM-DD') : undefined,
      initial_date: initial_date ? moment(initial_date).format('YYYY-MM-DD') : undefined,
      final_date: final_date ? moment(final_date).format('YYYY-MM-DD') : undefined,
      service_id,
      selling_channel,
      wanted_fields,
      user_id
    }

    return { searchParams }
  }
}
