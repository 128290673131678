/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useFeatureFlag } from 'contexts/Feature'
import { identity } from '@bonitour/common-functions'
import { BusIcon, Card, CombinedExperienceIcon, EmptyResult, GhostButton, InputWithSuffix, LoadingAnimation, MountainIcon, OfflineExperienceIcon, Pill, Pills, RefreshIcon, Row, SearchIcon } from '@bonitour/components'
import { loadingContainer } from 'assets/styles/global'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { searchWidth, searchIcon, gridContainer, gridItem, customPillCss, customActivePillCss, customPillContainerCss, scrollLoadingContainer, listSelector, noScroll, compactPillContainerCss, compactPillCss, menuScroll, menuGrid, listContainer, forceUpdateButton, loadingUpdateButton, hideSmallHeight } from './ListSelector.style'
import { useDebounce } from '@bonitour/app-functions'
import { MemoizedActivityCard } from '../ActivityCard'
import { useListExperiences } from 'domains/Activity/hooks/ExperiencesList'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { ACTIVITY_TYPE, COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

const ExperiencesGridList = memo(({
  onExperienceClick: emitClick = identity,
  searchQuery,
  typeFilter,
  showActionLabel = false,
  disableScroll = false,
  fromCurrentCompany = undefined,
  disabledTypes = [],
  smallCards = false,
  subdomain = null,
  onlyFirstPage = false
}) => {
  const onExperienceClick = useCallback((id, type) => () => emitClick(id, type), [emitClick])

  const {
    setQuery,
    setFilter,
    experiencesList,
    isLoading,
    loadNextPage,
    isOnLastPage,
    forceUpdate
  } = useListExperiences({ disabledTypes, fromCurrentCompany, onlyFirstPage })

  useEffect(() => {
    setQuery({ search: searchQuery })
  }, [searchQuery, setQuery])
  useEffect(() => {
    setFilter({ types: typeFilter })
  }, [typeFilter, setFilter])

  const scrollRef = useRef()

  const gridItemCss = useMemo(() => gridItem(showActionLabel, smallCards), [smallCards, showActionLabel])

  return (
    <>
      {!smallCards && (
        <GhostButton onClick={forceUpdate} css={[forceUpdateButton, isLoading && loadingUpdateButton]}>
          <RefreshIcon />
          <p>Atualizar lista</p>
        </GhostButton>
      )}
      <div css={[listSelector, disableScroll && noScroll, smallCards && menuScroll, disableScroll && noScroll]} ref={scrollRef}>
        {!experiencesList?.length && (
          isLoading
            ? <LoadingAnimation css={loadingContainer} />
            : <EmptyResult title={searchQuery ? 'Sem resultados' : ''} subtitle='Nenhuma experiência encontrada' />
        )}
        <div css={[gridContainer, smallCards && menuGrid]}>
          {experiencesList.map(activity => (
            <Card css={gridItemCss} key={`activity-${activity.id}`}>
              <MemoizedActivityCard
                activity={activity}
                onActivityClick={onExperienceClick(activity.id, activity.type)}
                hideActionLabel={!showActionLabel}
                squareCard={!smallCards}
                showNameTooltip={!smallCards}
                hasTitleMaxWidth={!smallCards}
                subdomain={subdomain}
              />
            </Card>
          ))}
        </div>
        {!isOnLastPage && experiencesList?.length && !onlyFirstPage
          ? (
            <>
              <LoadingAnimation css={scrollLoadingContainer(showActionLabel, disableScroll, smallCards)} />
              <InfiniteScroll nextPage={loadNextPage} loading={isLoading} scrollElement={disableScroll ? undefined : scrollRef.current} distance={800} />
            </>
          )
          : null
        }
      </div>
    </>
  )
}, (prevProps, nextProps) =>
  prevProps.searchQuery === nextProps.searchQuery &&
  prevProps.typeFilter === nextProps.typeFilter
)

export const ExperienceListSelector = ({
  onSelectedExperience = identity,
  disabledTypes = [],
  disableScroll = false,
  showActionLabel = false,
  fromCurrentCompany = undefined,
  subdomain = null,
  smallCards = false,
  hideFilter = false,
  onlyFirstPage = false,
  ...other
}) => {
  const [isEnabledCombinedExperience, _, isFlagReady, isFlagTimedOut] = useFeatureFlag('orb-combined-experience')

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 1250)

  const [typeFilter, setTypeFilter] = useState([])
  const isTypeFilterActive = useCallback((type) => typeFilter.includes(type), [typeFilter])
  const toggleTypeFilter = useCallback((type) => () => {
    setTypeFilter((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type)
      } else {
        return [...prev, type].sort()
      }
    })
  }, [])

  const disabledExperienceTypes = useMemo(() => isEnabledCombinedExperience
    ? disabledTypes
    : [...disabledTypes, COMBINED_EXPERIENCE_TYPE],
  [disabledTypes, isEnabledCombinedExperience])

  const typeFilterOptions = useMemo(
    () => [
      { label: 'Atividades', value: ACTIVITY_TYPE, icon: <MountainIcon /> },
      { label: 'Transportes', value: TRANSPORT_TYPE, icon: <BusIcon /> },
      { label: 'Experiencias Combinadas', value: COMBINED_EXPERIENCE_TYPE, icon: <CombinedExperienceIcon /> },
      { label: 'Experiencias Offline', value: OFFLINE_EXPERIENCE_TYPE, icon: <OfflineExperienceIcon /> }
    ].filter(({ value }) => !disabledExperienceTypes.includes(value)),
    [disabledExperienceTypes]
  )

  const isLoadingFlag = useMemo(() => !isFlagReady && !isFlagTimedOut, [isFlagReady, isFlagTimedOut])

  return (
    <div {...other} css={[listContainer, other.css]}>
      {isLoadingFlag
        ? (
          <LoadingAnimation css={loadingContainer} />
        )
        : (
          <>
            {!hideFilter && (
              <>
                <Row>
                  <InputWithSuffix
                    css={searchWidth}
                    value={searchQuery}
                    onChange={setSearchQuery}
                    placeholder={`Buscar por nome${fromCurrentCompany ? '' : ' ou fornecedor'}`}
                  >
                    <SearchIcon css={searchIcon} />
                  </InputWithSuffix>
                </Row>
                {typeFilterOptions.length > 1 && (
                  <Pills customCss={[smallCards ? compactPillContainerCss : customPillContainerCss, !disableScroll && hideSmallHeight]}>
                    {typeFilterOptions.map(({ label, value, icon }) => (
                      <Pill
                        label={label}
                        active={isTypeFilterActive(value)}
                        onClick={toggleTypeFilter(value)}
                        key={value}
                        icon={icon}
                        customActiveCss={customActivePillCss}
                        customCss={[smallCards ? compactPillCss : customPillCss]}
                      />
                    ))}
                  </Pills>
                )}
              </>
            )}
            <ExperiencesGridList
              searchQuery={debouncedSearchQuery}
              typeFilter={typeFilter}
              onExperienceClick={onSelectedExperience}
              disabledTypes={disabledExperienceTypes}
              disableScroll={disableScroll}
              showActionLabel={showActionLabel}
              fromCurrentCompany={fromCurrentCompany}
              subdomain={subdomain}
              smallCards={smallCards}
              onlyFirstPage={onlyFirstPage}
            />
          </>
        )
      }
    </div>
  )
}
