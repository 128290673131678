/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Card, Fab, H4, Input, InputFormGroup, Row, SearchIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { AffiliateSelector } from './AffiliateSelector'
import { useCallback, useState } from 'react'
import { wrapper, cardWrapper, littleInput, marginBottom20, addAffiliateBtnStyle } from './Affiliate.styles'

const defaultFormEvent = () => identity

const AffiliateInputLabel = ({ toggleVisibility = identity, isDisabled = false }) => {
  return <>
    Código de afiliado
    <Button css={addAffiliateBtnStyle} onClick={toggleVisibility} disabled={isDisabled} className={'select-affiliate-btn'}>
      <Fab>
        <SearchIcon/>
      </Fab>
      <span/>
    </Button>
  </>
}

export const Affiliate = ({
  affiliate = '',
  affiliateErrors = '',
  onChange: emitChangeEvent = defaultFormEvent,
  onBlur: emitBlurEvent = defaultFormEvent,
  fieldRegister: register = null,
  onSelectAffiliate = identity,
  isDisabled = false,
  customCardCss = [],
  children
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = useCallback(() => setIsVisible(previous => !previous), [])

  return (
    <div css={wrapper}>
      <H4>Afiliado</H4>
      <Card css={[cardWrapper, ...customCardCss]} className={isDisabled && 'disabled'}>
        <Row>
          <InputFormGroup
            id={affiliate}
            label={<AffiliateInputLabel toggleVisibility={toggleVisibility} isDisabled={isDisabled}/>}
            errorMessage={affiliateErrors}
            css={marginBottom20}
          >
            <Input
              value={affiliate}
              css={littleInput}
              onChange={emitChangeEvent('affiliateCode')}
              onBlur={emitBlurEvent('affiliateCode')}
              disabled={isDisabled}
              onChangeWholeEvent={Boolean(register)}
              {...{
                ...(register ? register('affiliateCode') : {})
              }}
            />
          </InputFormGroup>
        </Row>
        {children}
      </Card>
      <AffiliateSelector
        isVisible={isVisible}
        onClose={toggleVisibility}
        onSelectAffiliate={register ? onSelectAffiliate : emitChangeEvent('affiliateCode')}
      />
    </div>
  )
}
