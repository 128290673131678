import moment from 'moment'

export const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export const isTodayOrAfter = date => moment().isBefore(moment(date).add(1, 'day'), 'day')
export const isTodayOrAfterOrNull = date => !date || isTodayOrAfter(date)
export const isDateInFuture = date => moment().isBefore(moment(date), 'day')

export const brazilianDateFormat = date => moment(date, 'DD-MM-YYYY').format('LL')

export const removeUtcTimezone = date => {
  if (!date || typeof date !== 'string') {
    throw new Error('Invalid date')
  }

  if (date.includes('Z')) {
    return date.split('Z')[0]
  }

  return date
}
