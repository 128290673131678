/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  InputFormGroup,
  Button,
  useToast,
  Row,
  Column,
  DatePicker,
  Input,
  MultiSelectDropdown
} from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useEffect, memo } from 'react'
import { useKeyPress } from 'hooks/useKeyPress'
import { button, cardContrast, submitButton, width150 } from './MyDayFilters.style'
import { useMyDay, sellers, vendors } from '../hooks/useMyDay'
import { ticketStatusOptions } from 'constants/ticketStatus'
import { useMyDayFilters } from '../hooks/useMyDayFilters'

const DEFAULT_LAYOUT_SIZES = {
  tablet: 4,
  desktop: 4
}

const Filters = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {},
  activityOptions = [],
  getActivityOptions
}) => {
  const { add: addToast } = useToast()

  useMyDay()

  const {
    form,
    onInputChange,
    errors,
    onSubmit,
    onChangeCities,
    onChangeCountries,
    onChangeStates,
    localitiesData = {}
  } = useMyDayFilters({ initialFilters })

  const {
    options: {
      citiesOptions,
      countriesOptions,
      statesOptions
    },
    isLoading: {
      cities: isLoadingCitiesData,
      countries: isLoadingCountriesData,
      states: isLoadingStatesData
    },
    placeholders: {
      cities: citiesMultiSelectPlaceholder,
      countries: countriesMultiSelectPlaceholder,
      states: statesMultiSelectPlaceholder
    },
    isSelectedManyCountries,
    isSelectedManyStates,
    isEmptySelectedCountries,
    isEmptySelectedStates
  } = localitiesData

  const {
    passengerName = '',
    ticketCode = '',
    servicesId = [],
    partnerId = '',
    date = '',
    sellerId,
    providerId,
    ticketStatus = '',
    countries = [],
    states = [],
    cities = []
  } = form

  const { onKeyDown } = useKeyPress()

  useEffect(() => {
    getActivityOptions(partnerId)
    onInputChange('servicesId')([])
    // eslint-disable-next-line
  }, [partnerId])

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Data" errorMessage={errors.date}>
            <DatePicker
              value={date}
              onChange={onInputChange('date')}
              css={width150}
              allowsEmpty
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Cliente" errorMessage={errors.passengerName}>
            <Input
              value={passengerName}
              error={errors.passengerName}
              onChange={onInputChange('passengerName')}
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Cód do ingresso" errorMessage={errors.ticketCode}>
            <Input
              value={ticketCode}
              error={errors.ticketCode}
              onChange={onInputChange('ticketCode')}
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Experiência" errorMessage={errors.servicesId}>
            <MultiSelectDropdown
              options={activityOptions}
              disabled={!activityOptions?.length}
              selected={servicesId}
              error={errors.servicesId}
              onChange={onInputChange('servicesId')}
              placeholder={'Selecione uma experiência'}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Fornecedores" errorMessage={errors.providerId}>
            <MultiSelectDropdown options={vendors.value} selected={providerId} onChange={onInputChange('providerId')} placeholder={'Selecione um fornecedor'} />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Vendedores" errorMessage={errors.sellerId}>
            <MultiSelectDropdown options={sellers.value} selected={sellerId} onChange={onInputChange('sellerId')} placeholder={'Selecione um vendedor'}/>
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="País" errorMessage={errors.countries}>
            <MultiSelectDropdown
              options={countriesOptions}
              selected={countries}
              onChange={onChangeCountries}
              placeholder={countriesMultiSelectPlaceholder}
              isLoading={isLoadingCountriesData}
              isDisableSelectAll={true}
              disabled={isLoadingCountriesData}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Estado" errorMessage={errors.states}>
            <MultiSelectDropdown
              options={statesOptions}
              selected={states}
              onChange={onChangeStates}
              disabled={isEmptySelectedCountries || isSelectedManyCountries || isLoadingStatesData || isLoadingCountriesData}
              placeholder={statesMultiSelectPlaceholder}
              isDisableSelectAll={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Cidade" errorMessage={errors.cities}>
            <MultiSelectDropdown
              options={citiesOptions}
              selected={cities}
              onChange={onChangeCities}
              disabled={isEmptySelectedStates || isSelectedManyStates || isLoadingCitiesData}
              placeholder={citiesMultiSelectPlaceholder}
              isDisableSelectAll={true}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop}>
          <InputFormGroup label="Status do ingresso">
            <MultiSelectDropdown
              options={ticketStatusOptions}
              selected={ticketStatus}
              onChange={onInputChange('ticketStatus')}
              placeholder={'Selecione um status'}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={DEFAULT_LAYOUT_SIZES.tablet} desktop={DEFAULT_LAYOUT_SIZES.desktop} css={submitButton}>
          <Button css={button} onClick={onClick}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}

export const MyDayFilters = memo(Filters)
