/** @jsxRuntime classic */
/** @jsx jsx  */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { Card, Select, InputWithSuffix, InputMoneyMask, TrashIcon, flexHorizontallyCentered, HorizontalInputFormGroup } from '@bonitour/components'
import { unformatMoney } from '@bonitour/common-functions'
import { clickable } from 'assets/styles/global'
import {
  width170,
  marginTop20,
  relativeCard,
  inputMargin,
  modificatorSize,
  finalPriceSize,
  trashIcon,
  flexColumnCenter,
  marginTop10,
  vacancyFieldContainer
} from './FeeByDemandRow.style'
import { Controller } from 'react-hook-form'

const feeByDemandOperatorOptions = [
  {
    label: 'acrescentar',
    value: 'increase'
  },
  {
    label: 'decrescer',
    value: 'decrease'
  }
]

export const FeeByDemandRow = ({
  feePrice = 0,
  index = 0,
  errors = {},
  feeByDemandItem,
  handleRemoveFeeByDemand,
  control,
  update
}) => {
  const {
    limit = 0, operator = 'increase',
    valueModificator = 0,
    finalValue = feePrice
  } = feeByDemandItem

  const onChangeLimit = useCallback(
    (value) => {
      update(index, {
        ...feeByDemandItem,
        limit: value
      })
    }
    , [feeByDemandItem, index, update])

  const onChangeValueModificator = useCallback(
    (value) => {
      const parsedValue = Number(value || 0)
      const normalizedValue = parsedValue > 100 && operator === 'decrease' ? 100 : parsedValue

      const adjustment = (normalizedValue / 100) * feePrice

      const operations = {
        decrease: feePrice - adjustment,
        increase: feePrice + adjustment
      }

      const adjustedFinalValue = operations[operator]

      update(index, {
        ...feeByDemandItem,
        valueModificator: normalizedValue,
        finalValue: adjustedFinalValue
      })
    },
    [feeByDemandItem, feePrice, index, operator, update]
  )

  const onChangeOperator = useCallback(
    (value) => {
      const percentage = {
        decrease: -1,
        increase: 1
      }

      const parsedValue = Number(valueModificator || 0)
      const safeValueModificator = parsedValue > 100 && value === 'decrease' ? 100 : parsedValue

      const newFinalValue = feePrice + percentage[value] * (safeValueModificator / 100) * feePrice

      update(index, {
        ...feeByDemandItem,
        operator: value,
        valueModificator: safeValueModificator,
        finalValue: newFinalValue
      })
    }
    , [feeByDemandItem, feePrice, index, update, valueModificator])

  const onChangeFinalValue = useCallback(
    (value) => {
      const unformattedValue = unformatMoney(value)
      if (!feePrice) {
        return
      }

      const newOperator = (unformattedValue < feePrice) ? 'decrease' : 'increase'

      const percentageValue = Math.abs(parseFloat(((unformattedValue - feePrice) / feePrice * 100))).toFixed(4)

      update(index, {
        ...feeByDemandItem,
        valueModificator: percentageValue,
        operator: newOperator,
        finalValue: unformattedValue
      })
    }
    , [feeByDemandItem, feePrice, index, update])

  return (
    <Card css={[marginTop20, relativeCard]}>
      <div css={[flexHorizontallyCentered, flexColumnCenter]}>
        <div css={[vacancyFieldContainer]}>
          <span>Após</span>
          <HorizontalInputFormGroup
            css={[inputMargin]}
            errorMessage={errors?.limit?.message}
          >
            <Controller
              control={control}
              name={`feesByDemands.${index}.limit`}
              render={({ field: { onBlur } }) => (
                <InputWithSuffix
                  css={[modificatorSize, marginTop10]}
                  type='number'
                  min='0'
                  max='100'
                  value={limit}
                  onBlur={onBlur}
                  onChange={onChangeLimit}
                >
                  <span>%</span>
                </InputWithSuffix>
              )}
            />
          </HorizontalInputFormGroup>
        </div>
        <span css={marginTop10}>das vagas</span>
        <HorizontalInputFormGroup
          css={[inputMargin]}
          errorMessage={errors?.operator?.message}
        >
          <Controller
            control={control}
            name={`feesByDemands.${index}.operator`}
            render={({ field: { onBlur } }) => (
              <Select
                css={[width170, marginTop10]}
                options={feeByDemandOperatorOptions}
                value={operator}
                onChange={onChangeOperator}
                onBlur={onBlur}
              />
            )}
          />

        </HorizontalInputFormGroup>

        <span css={marginTop10}>a porcentagem de</span>

        <HorizontalInputFormGroup
          css={[inputMargin]}
          errorMessage={errors?.valueModificator?.message}
        >
          <Controller
            control={control}
            name={`feesByDemands.${index}.valueModificator`}
            render={({ field: { onBlur } }) => (
              <InputWithSuffix
                type='number'
                min='0'
                css={[modificatorSize, marginTop10]}
                value={valueModificator}
                onChange={onChangeValueModificator}
                onBlur={onBlur}
              >
                <span>%</span>
              </InputWithSuffix>
            )}
          />
        </HorizontalInputFormGroup>

        <span css={marginTop10}>com o valor final de</span>
        <HorizontalInputFormGroup
          css={[inputMargin]}
          errorMessage={errors?.finalValue?.message}
        >
          <InputMoneyMask
            value={finalValue}
            onChange={onChangeFinalValue}
            css={[finalPriceSize, marginTop10]}
          />
        </HorizontalInputFormGroup>

      </div>
      <TrashIcon css={[trashIcon, clickable]} onClick={handleRemoveFeeByDemand} />
    </Card>
  )
}
