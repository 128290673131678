
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'

const combinedExperienceBookingMapSchema = yup.object().shape({
  selectedActivities: yup.array()
    .of(yup.object().shape({
      serviceId: yup.string().required(),
      date: yup.string().required(),
      hour: yup.string().required(),
      feeName: yup.string().required(),
      quantity: yup.number().required()
    }))
})

export const useCombinedExperienceForm = () => {
  const {
    watch,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(combinedExperienceBookingMapSchema, {
      stripUnknown: true,
      abortEarly: false
    }),
    defaultValues: {
      selectedActivities: []
    },
    values: {
      selectedActivities: []
    }
  })

  const selectedActivitiesValues = watch('selectedActivities')

  const onSelectActivity = useCallback((activities) => {
    activities.forEach(({ id, date, hour, feeName, quantity }) => {
      const filter = (activity) => activity.serviceId === id && activity.date === date && activity.hour === hour && activity.feeName === feeName
      const sameActivityDataIndex = selectedActivitiesValues.findIndex(filter)

      if (sameActivityDataIndex !== -1) {
        selectedActivitiesValues[sameActivityDataIndex].quantity = quantity

        setValue('selectedActivities', selectedActivitiesValues)
      } else {
        selectedActivitiesValues
          .push({ serviceId: id, date, hour, feeName, quantity })

        setValue('selectedActivities', selectedActivitiesValues)
      }
    })
  }, [selectedActivitiesValues, setValue])

  return {
    onSelectActivity,
    resetForm: reset,
    values: {
      selectedActivities: selectedActivitiesValues
    }
  }
}
