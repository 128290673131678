/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { TableBodyCell } from '@bonitour/components'

import { summaryContainer, vacancyTableBodyCell } from './summaryContainer.styles'

export const SummaryContainer = ({ canDisableWhiteSpace = false, children }) => {
  return (
    <TableBodyCell css={vacancyTableBodyCell}>
      <div className={canDisableWhiteSpace ? 'disable-white-space' : ''} css={summaryContainer}>{children}</div>
    </TableBodyCell>
  )
}
