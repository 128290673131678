import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'
import { clickable, margin, marginBottom, marginLeft, marginTop } from 'assets/styles/global'

export const marginHorizontal15 = margin(0, 15, 0, 15)
export const marginTop20 = marginTop(20)
export const marginTop40 = marginTop(40)
export const marginBottom20 = marginBottom(20)
export const marginRight10 = margin(0, 10, 0, 0)
export const marginLeft30 = marginLeft(30)

export const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const fiscalDutyCss = css`
  padding: 10px 5px;
  color: ${colors.gray3};
`

export const iconSize = css`
  font-size: 23px;
  color: ${colors.gray3};
  ${clickable};
`

export const documentLabel = css`
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: ${colors.gray3};
  background-color: ${colors.gray7};
`

export const fullWidth = css`
  width: 100%;
`
export const observations = css`
  margin: 20px 0;
`

const disabledButtonPrimary = css`
  opacity: 0.3;
  cursor: not-allowed;
`

const buttonPrimary = (ctxColor, disabled) => css`
  background-color: ${colors.white};
  border: 1px solid ${ctxColor};
  color: ${colors.primary};
  -webkit-text-fill-color: ${ctxColor};
  cursor: pointer;
  ${disabled ? disabledButtonPrimary : ''};
`

export const ghostButtonPrimary = (disabled = false) => buttonPrimary(colors.primary, disabled)

export const buttonsContainer = css`
  display: flex;
  align-items: center;
  margin: 30px 0 10px 10px;
`

export const sendEmailLink = css`
  color: ${colors.primary};
  ${clickable};
  text-decoration: underline;
`

export const inputButton = css`
  margin: 5px 0px;
  padding: 5px 10px;
  font-size: 12px;
  color: ${colors.primary};
  border-color: ${colors.primary};
`

export const label = css`
  font-size: 14px;
  color: ${colors.gray4};
`

export const reservationPassengerContainer = css`
  margin: 15px 0;
  max-height: 140px;
  overflow-y: auto;
  ${scrollBar(5)}
`

export const addPayerLabel = css`
  margin: 20px 0;
  font-weight: bold;
  color: ${colors.gray3};
`
export const inputStyle = css`
  width: 120px;
`
