/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popover, Counter, ClockIcon } from '@bonitour/components'
import { useClickOutside, useDebounce } from '@bonitour/app-functions'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ServiceCombinedExperienceList } from '../ServiceCombinedExperienceList/ServiceCombinedExperienceList'
import { formatMoney, identity } from '@bonitour/common-functions'
import moment from 'moment'
import {
  durationStyle, headerStyle, emphasisInfo, popoverContainer, counterButtons, timeStyle, feeRow, counterWrapper,
  feeRowContainer
} from './CombinedExperiencePaxSelector.style'
import { effect } from '@preact/signals-core'
import { isScrolling } from 'domains/Booking/Map/bookingMapStates'

export const CombinedExperiencePaxSelector = memo(({
  onSelectActivity = identity,
  activitiesFees,
  hasActivitiesFees,
  activityIndex,
  hour = '',
  duration = '',
  color = '',
  limitOfDay = '',
  date = '',
  children
}) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = useCallback(() => hasActivitiesFees ? setVisibility(!isVisible) : identity(), [hasActivitiesFees, isVisible, setVisibility])
  const parseToDuration = useCallback((time) => moment.duration(time), [])
  const formattedDuration = useMemo(() => {
    const totalDuration = parseToDuration(hour)
      .add(parseToDuration(duration))
    return moment.utc(totalDuration.asMilliseconds()).format('HH:mm')
  }, [parseToDuration, hour, duration])

  const [quantity, setQuantity] = useState({})
  const debouncedPaxSelection = useDebounce(quantity, 600)

  const handleCounterChange = useCallback((feeName) => (newQuantity) => setQuantity(prevQuantity => ({
    ...prevQuantity,
    [feeName]: newQuantity
  })), [])

  effect(() => {
    if (isScrolling.value && isVisible) {
      setVisibility(!isVisible)
    }
  })

  useEffect(() => {
    const activitiesPayload = Object.keys(debouncedPaxSelection)
      .map((feeName) => ({
        id: activitiesFees?.[activityIndex].id,
        hour,
        date,
        feeName,
        quantity: debouncedPaxSelection[feeName]
      }))
    onSelectActivity(activitiesPayload)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPaxSelection])

  return (
    <div ref={reference}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility}>
              {children}
            </div>
          )}
        </Reference>
        {
          isVisible
            ? (
              <Popover position="right" css={popoverContainer}>
                <div css={headerStyle}>
                  <div>
                    <ServiceCombinedExperienceList experiences={[activitiesFees?.[activityIndex]]} color={color}/>
                  </div>
                  <div css={durationStyle}>
                    <ClockIcon />
                    <div css={timeStyle}>
                      <span className='bold'>{hour}</span>
                      <span>até</span>
                      <span className='bold'>{formattedDuration}</span>
                    </div>
                  </div>
                </div>
                <div css={feeRowContainer}>
                  {activitiesFees?.[activityIndex]?.fees.map((fee, index) => (
                    <div key={`pax-selector-#${activityIndex}-${index}`} css={feeRow}>
                      <div className='fee-details'>
                        <span css={emphasisInfo}>{fee.name}</span>
                        <span>{fee.minAge} - {fee.maxAge} anos</span>
                      </div>
                      <section>
                        <span css={emphasisInfo}>
                          {formatMoney(parseFloat(fee.pricing).toFixed(2))}
                        </span>
                        <div css={counterWrapper}>
                          <Counter
                            css={counterButtons}
                            value={quantity[fee.name] || 0}
                            min={0}
                            max={limitOfDay}
                            onChange={handleCounterChange(fee.name)}
                          />
                        </div>
                      </section>
                    </div>
                  ))}
                </div>
              </Popover>
            )
            : null
        }
      </Manager>
    </div>
  )
})

CombinedExperiencePaxSelector.propTypes = {
  activitiesFees: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    pricing: PropTypes.string.isRequired,
    minAge: PropTypes.number.isRequired,
    maxAge: PropTypes.number.isRequired
  })).isRequired
}
