import { colors } from '@bonitour/components'

export const StatusEnum = Object.freeze({
  reserved: 'reserved',
  confirmed: 'confirmed',
  canceled: 'canceled',
  quotation: 'quotation',
  checked_in: 'checked_in',
  used: 'used',
  noShow: 'no-show'
})

export const mainReservationsStatusOptions = [
  {
    value: StatusEnum.reserved,
    label: 'Reservado'
  },
  {
    value: StatusEnum.confirmed,
    label: 'Confirmado'
  },
  {
    value: StatusEnum.canceled,
    label: 'Cancelado'
  },
  {
    value: StatusEnum.checked_in,
    label: 'Checked-in'
  }
]

export const reservationStatusOptions = [
  {
    value: StatusEnum.reserved,
    label: 'Reservado'
  },
  {
    value: StatusEnum.canceled,
    label: 'Cancelado'
  }
]

export const reservationsStatusOptions = [
  {
    value: '',
    label: 'Todos'
  },
  ...mainReservationsStatusOptions
]

export const reservationStatusDictionary = {
  [StatusEnum.reserved]: { label: 'Reservado', color: colors.orange1 },
  [StatusEnum.quotation]: { label: 'Em cotação', color: colors.gray4 },
  [StatusEnum.confirmed]: { label: 'Confirmado', color: colors.green1 },
  [StatusEnum.canceled]: { label: 'Cancelado', color: colors.danger },
  [StatusEnum.checked_in]: { label: 'Checked-in', color: colors.blue1 }
}
