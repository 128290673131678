/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flexHorizontallyCentered, DatePicker, TrashIcon, flexRowCentered, HorizontalInputFormGroup, Input } from '@bonitour/components'
import { width, clickable, alignTrashIconAtEnd } from 'assets/styles/global'
import { rowMargin, flexColumn, iconSize, marginTop10, sameLineInput, width150, width220 } from './PeriodRow.style'
import { Controller } from 'react-hook-form'

export function TarifPeriodRow ({
  placeholder = '',
  errors = {},
  index = 0,
  register,
  control,
  removePeriodFromList
}) {
  return (
    <div css={[flexRowCentered, rowMargin]}>
      <div css={[flexHorizontallyCentered, width(95), flexColumn]}>
        <p css={sameLineInput}>Nome do período será</p>
        <HorizontalInputFormGroup css={width220} errorMessage={errors?.name?.message}>
          <Input
            placeholder={placeholder}
            css={marginTop10}
            onChangeWholeEvent={true}
            {...register(`periodList.${index}.name`)}

          />
        </HorizontalInputFormGroup>
        <p css={[sameLineInput, marginTop10]}>O início será em</p>
        <HorizontalInputFormGroup css={[width150, marginTop10]} errorMessage={errors?.startDate?.message}>
          <Controller
            control={control}
            name={`periodList.${index}.startDate`}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </HorizontalInputFormGroup>

        <p css={[sameLineInput, marginTop10]}>e o fim em</p>
        <HorizontalInputFormGroup css={[width150, marginTop10]} errorMessage={errors?.endDate?.message}>
          <Controller
            control={control}
            name={`periodList.${index}.endDate`}
            render={({ field: { onChange, onBlur, value } }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </HorizontalInputFormGroup>
      </div>
      <div css={[width(5), alignTrashIconAtEnd, flexHorizontallyCentered]}>
        {index !== 0 && <TrashIcon css={[iconSize, clickable]} onClick={removePeriodFromList} />}
      </div>
    </div>
  )
}
