/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { VacancyPopover } from '../Guides/VacancyPopover'
import { BookingCard } from 'domains/Booking/Map/Card/BookingCard'
import { VacancyCard } from 'domains/Booking/Map/Card/VacancyCard'
import { clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { SummaryContainer, DetailedBooking } from './components'
import { CombinedExperienceSummary } from './components/CombinedExperienceSummary'
import { useMemo } from 'react'
import { useBookingMapInfo } from '../../contexts/useBookingMapInfo'

const summaryWarningCss = css`
  border: 2px solid ${colors.red4};
`

export const VacancyTableSummaryCell = ({
  getFeesByDate = identity,
  date = '',
  rowHour = '',
  VacancySummaryPopoverContent,
  vacancies: { total: totalVacancies = 0 } = {},
  reservations: { total: totalReservations = 0, details: reservationDetails = [] } = {},
  isExpandable = true,
  isAllowedBookingDate,
  onExpand: emitExpandEvent = identity,
  ticketTariff,
  activitiesData = [],
  dateColumns = [],
  isGroupedCell = false
}) => {
  const hasVacancies = totalVacancies > 0
  const hasNegativeVacancy = totalVacancies < 0
  const activitiesFees = useMemo(() => {
    if (!isGroupedCell) return null
    const feesByDate = getFeesByDate(date, true)
    return feesByDate?.[rowHour] || []
  }, [isGroupedCell, getFeesByDate, date, rowHour])

  const {
    selectedActivities,
    onSelectActivity
  } = useBookingMapInfo()

  if (isGroupedCell) {
    return (
      <SummaryContainer canDisableWhiteSpace={isGroupedCell}>
        <CombinedExperienceSummary
          activitiesData={activitiesData}
          dateColumns={dateColumns}
          date={date}
          activitiesFees={activitiesFees}
          selectedActivities={selectedActivities}
          onSelectActivity={onSelectActivity}
        />
      </SummaryContainer>
    )
  }

  return (
    <SummaryContainer>
      {hasVacancies && isAllowedBookingDate
        ? (
          <VacancyPopover totalVacancies={totalVacancies} hour={rowHour} date={date} ticketTariff={ticketTariff} PopoverContent={VacancySummaryPopoverContent}>
            <VacancyCard>{totalVacancies}</VacancyCard>
          </VacancyPopover>
        )
        : (
          <VacancyCard css={[hasNegativeVacancy && summaryWarningCss]} disabled>
            {totalVacancies}
          </VacancyCard>
        )}
      {
        reservationDetails.length
          ? (
            <DetailedBooking
              totalReservations={totalReservations}
              reservationDetails={reservationDetails}
              css={[isExpandable && clickable]} onClick={isExpandable ? emitExpandEvent : identity}
            />
          )
          : (
            <BookingCard disabled={!totalReservations}>
              {totalReservations}
            </BookingCard>
          )
      }
    </SummaryContainer>
  )
}
