/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, scrollBar, flexColumnCentered } from '@bonitour/components'
import { identity, head } from '@bonitour/common-functions'
import { VacancyTableHeader } from 'domains/Booking/Map/Table/Header/VacancyTableHeader'
import { VacancyTableRow } from 'domains/Booking/Map/Table/Row/VacancyTableRow'
import { marginBottom } from 'assets/styles/global'
import { useCallback, useMemo } from 'react'
import { MIN_VACANCY_WARN_PERCENTAGE } from 'constants/bookingMap'
import { calculateHouliesAvailable, canHideActivityFilter, filterInvalidActivitiesAndFillData } from './vacancyTable.utils'
import { isScrolling } from '../bookingMapStates'

const tableBody = css`
  position: relative;
  padding: 10px 0;
  min-height: 165px;
  max-height: calc(100vh - 440px);
  overflow: auto;
  ${scrollBar(5)};
`

const block = css`
  display: block;
`

const scrollTable = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`

const marginBottom20 = marginBottom(20)

const VacancyTable = ({
  date = new Date(),
  isExpandable = true,
  dateColumns = [],
  debouncedDateColumns = [],
  tablePrices = {},
  tableData = {},
  activitiesRegistryAndPrice = [],
  tableDetailedData = {},
  isLoading = false,
  isActivityOwner = false,
  isCombinedExperience = false,
  VacancySummaryPopoverContent,
  currentExpandedRowLabel,
  onExpand: emitExpandEvent = identity,
  onExceptionalDayEditClick = identity
}) => {
  const isExpanded = useCallback(hour => hour === currentExpandedRowLabel, [currentExpandedRowLabel])

  const tableDataValues = useMemo(() => Object.values(tableData), [tableData])

  /**
   * @param {string} dateString Date in 'YYYY-MM-DD' format
   * @returns {number} Daily vacancy limit
   */
  const getLimitOfDay = useCallback((dateString) => {
    const day = head(tableDataValues.filter((datesObject) => Boolean(datesObject?.[dateString])))?.[dateString]

    if (!day) return 0

    const limitOfDay = day?.limitOfDay || 0

    return limitOfDay
  }, [tableDataValues])

  /**
   * @param {string} dateString Date in 'YYYY-MM-DD' format
   * @returns {boolean, boolean, boolean, boolean, number} First value is if the limit of the day is near, second value is if the limit of the day was reached, third value is if the limit of the day is safe, fourth value is if the daily limit is greater than the taotal vacancies of day, fifth value is the total of occupied vacancies
   */
  const checkCanShowLimitOfDay = useCallback((dateString) => {
    const [occupiedVacanciesTotals, freeVacancyTotals] = tableDataValues.reduce((acc, currDatesData) => {
      const dateData = currDatesData?.[dateString]

      if (!dateData) return acc

      const totalOccupiedVacancies = dateData?.reservations?.total + acc[0]
      const totalFreeVacancies = dateData?.vacancies?.total + acc[1]

      return [totalOccupiedVacancies, totalFreeVacancies]
    }, [0, 0])

    // It's determine that haven't vacancy
    if (freeVacancyTotals === 0 && occupiedVacanciesTotals === 0) {
      return [false, false, false, false, occupiedVacanciesTotals]
    }

    const limitOfDay = getLimitOfDay(dateString)

    const {
      isReachedLimitOfDay,
      isNearLimitOfDay,
      isSafeLimitOfDay,
      isDailyLimitGreatherThanTotalVacanciesOfDay
    } = (function () {
      const isReached = occupiedVacanciesTotals >= limitOfDay

      const isNear = isReached
        ? false
        : (function () {
          const freeVacanciesPercentage = ((limitOfDay - occupiedVacanciesTotals) / limitOfDay) * 100

          const isReachedMinVacancyWarn = (freeVacanciesPercentage <= (100 - MIN_VACANCY_WARN_PERCENTAGE))

          return isReachedMinVacancyWarn
        }())

      const isSafe = !isReached && !isNear

      const isDailyLimitGreatherThanTotalVacanciesOfDay = (function () {
        const totalVacanciesOfDay = freeVacancyTotals + occupiedVacanciesTotals

        const isLimitGreaterThanVancaciesOfDay = limitOfDay > totalVacanciesOfDay

        return isLimitGreaterThanVancaciesOfDay && !isReached
      }())

      return {
        isReachedLimitOfDay: isReached,
        isNearLimitOfDay: isNear,
        isSafeLimitOfDay: isSafe,
        isDailyLimitGreatherThanTotalVacanciesOfDay
      }
    }())

    if (occupiedVacanciesTotals && !limitOfDay) {
      return [
        false,
        false,
        false,
        false,
        0
      ]
    }

    return [
      isNearLimitOfDay,
      isReachedLimitOfDay,
      isSafeLimitOfDay,
      isDailyLimitGreatherThanTotalVacanciesOfDay,
      occupiedVacanciesTotals
    ]
  }, [getLimitOfDay, tableDataValues])

  const canSkipCombinedExperience = useMemo(() => !isCombinedExperience || !activitiesRegistryAndPrice || !Array.isArray(activitiesRegistryAndPrice) || isLoading, [isCombinedExperience, activitiesRegistryAndPrice, isLoading])

  const [activitiesByHour, hourliesAvailable] = useMemo(() => {
    if (canSkipCombinedExperience) return [{}, []]

    const hourliesAvailableWithoutFilter = calculateHouliesAvailable(activitiesRegistryAndPrice)

    const activitiesByHourly = hourliesAvailableWithoutFilter.reduce((acc, hour) => {
      const timeSlotActivities = activitiesRegistryAndPrice
        .map((activity) => {
          const { registries, id, color, duration, prices, image, name } = activity || {}

          const dataByHour = registries?.[hour]

          if (!dataByHour) return null

          const filteredSlots = Object.entries(dataByHour)
            .reduce(filterInvalidActivitiesAndFillData, {})

          if (!Object.keys(filteredSlots).length) return null

          return {
            slots: filteredSlots,
            activityId: id,
            color,
            duration,
            hour,
            prices,
            image,
            name
          }
        })
        .filter(canHideActivityFilter)

      if (!timeSlotActivities.length) return acc

      acc[hour] = timeSlotActivities
      return acc
    }, {})

    const hourliesAvailable = Object.keys(activitiesByHourly)

    return [
      activitiesByHourly,
      hourliesAvailable
    ]
  }, [activitiesRegistryAndPrice, canSkipCombinedExperience])

  const getFeesByDate = useCallback((date, canSeparateByHour = false) => {
    const processedActivityIds = new Set()

    return Object.keys(activitiesByHour).reduce((acc, currHour) => {
      const activities = Object.entries(activitiesByHour[currHour]).reduce((activityAcc, [_hourIndex, activityData]) => {
        let fees = activityData.prices?.[date]

        if (!fees || !fees.length || processedActivityIds.has(activityData.activityId)) {
          return activityAcc
        }

        if (!canSeparateByHour) {
          processedActivityIds.add(activityData.activityId)
        }

        fees = fees.sort((a, b) => Number(a.pricing) - Number(b.pricing)).reverse()

        const biggerFee = Math.max(...fees.map(({ pricing }) => Number.parseFloat(pricing)))

        activityAcc.push({
          id: activityData.activityId,
          color: activityData.color,
          serviceTitle: activityData.name,
          serviceImage: activityData.image,
          name: activityData.name,
          biggerFee,
          fees
        })

        return activityAcc
      }, [])

      if (canSeparateByHour) {
        acc[currHour] = activities
        return acc
      }

      acc.push(...activities)
      return acc
    }, canSeparateByHour ? {} : [])
  }, [activitiesByHour])

  const hasVacanciesData = useMemo(() => {
    if (isCombinedExperience) {
      return Boolean(!canSkipCombinedExperience && hourliesAvailable.length)
    }
    return Object.keys(tableData).length
  }, [isCombinedExperience, tableData, canSkipCombinedExperience, hourliesAvailable.length])

  const defaultVacancyTableAttrs = useMemo(() => ({
    css: scrollTable,
    VacancySummaryPopoverContent,
    dateColumns: debouncedDateColumns
  }), [VacancySummaryPopoverContent, debouncedDateColumns])

  const handleScroll = useCallback(() => {
    if (!isScrolling.value) isScrolling.value = true
  }, [])

  return (
    <TableContainer css={isLoading && flexColumnCentered}>
      <VacancyTableHeader
        selectedDate={date}
        dateColumns={dateColumns}
        tablePrices={tablePrices}
        getLimitOfDay={getLimitOfDay}
        css={[scrollTable, isLoading && marginBottom20]}
        onExceptionalDayEditClick={onExceptionalDayEditClick}
        isActivityOwner={isActivityOwner}
        checkCanShowLimitOfDay={checkCanShowLimitOfDay}
        isCombinedExperience={isCombinedExperience}
        activitiesByHour={activitiesByHour}
        getFeesByDate={getFeesByDate}
        isLoadingVacancies={isLoading}
      />
      <TableBody css={[tableBody, hasVacanciesData && block]} loading={isLoading} onScroll={handleScroll}>
        {isCombinedExperience
          ? hourliesAvailable.map((hour) => (
            <VacancyTableRow
              {...defaultVacancyTableAttrs}
              key={`date-row-hour-${hour}`}
              isExpandable={false}
              rowHour={hour}
              isCombinedExperience={isCombinedExperience}
              activitiesList={activitiesByHour[hour]}
              getFeesByDate={getFeesByDate}
            />
          ))
          : Object.entries(tableData).map(([hour, datesData]) => (
            <VacancyTableRow
              {...defaultVacancyTableAttrs}
              isExpandable={isExpandable}
              key={`date-row-hour-${hour}`}
              rowHour={hour}
              isExpanded={isExpanded(hour)}
              onExpand={emitExpandEvent}
              datesData={datesData}
              tableDetailedData={tableDetailedData}
              onExceptionalDayEditClick={onExceptionalDayEditClick}
              tablePrices={tablePrices}
            />
          ))}
      </TableBody>
    </TableContainer>
  )
}

export default VacancyTable
