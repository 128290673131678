import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'

export const useFinanceDeletePrompt = ({ removePayment, removeRefund }) => {
  const { add: addToast } = useToast()
  const [visibleMethod, setVisibility] = useState(false)
  const [id, setId] = useState('')
  const [error, setError] = useState(false)

  const deletePayment = useCallback(async (paymentId, reasonDelete) => {
    try {
      await removePayment(paymentId, reasonDelete)
      addToast('Pagamento excluído', 'success')
    } catch (error) {
      const errorData = error?.data

      const errorMap = {
        'financial::cashier_closed::failure': 'Não é possível excluir um pagamento com o caixa fechado',
        default: 'Erro ao cancelar pagamento'
      }

      let errorsShowed = 0

      for (const error of (errorData?.errors_msg || [])) {
        const errorMessage = errorMap?.[error]
        if (errorMessage) {
          addToast(errorMessage)
          errorsShowed += 1
        }
      }

      if (!errorsShowed) {
        addToast(errorMap.default)
      }
    }
  }, [addToast, removePayment])

  const deleteFailure = useCallback(async (message = 'Informe o motivo da exclusão') => {
    setError(message)
    addToast(message)
  }, [addToast])

  const deleteRefund = useCallback(async (refundId, reasonDelete) => {
    try {
      await removeRefund(refundId, reasonDelete)
      addToast('Reembolso excluído', 'success')
    } catch (error) {
      addToast('Erro ao cancelar Reembolso')
    }
  }, [addToast, removeRefund])

  const successCallback = onSuccess => reason => {
    if (!reason) {
      deleteFailure()
    } else {
      onSuccess(id, reason)
      setVisibility(false)
    }
  }

  const cancelCallback = () => {
    setVisibility(false)
    setError(false)
  }

  const deleteMethodsMap = {
    payment: {
      title: 'Excluir Pagamento',
      successCallback: successCallback(deletePayment),
      cancelCallback,
      isVisible: visibleMethod === 'payment'
    },
    refund: {
      title: 'Excluir Reembolso',
      successCallback: successCallback(deleteRefund),
      cancelCallback,
      isVisible: visibleMethod === 'refund'
    }
  }

  const openDialog = method => (rowId) => {
    setId(rowId)
    setVisibility(method)
  }

  const promptProps = {
    inputProps: { required: true },
    inputLabel: 'Motivo da exclusão',
    errorMessage: error,
    ...deleteMethodsMap[visibleMethod]
  }

  return {
    openDialog,
    promptProps
  }
}
