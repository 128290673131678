/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, P, colors, BREAK_POINTS } from '@bonitour/components'
import { useMemo, memo } from 'react'
import { paxTypesList } from 'constants/paxTypes'
import { FeePaxDetails } from './FeePaxTypeDetails'
import { ADITIONAL_FEE_FILTER, DEFAULT_FEE_FILTER, UNIQUE_FEE_FILTER } from 'constants/fees'

const marginBottom20 = marginBottom(20)
const marginBottom10 = marginBottom(10)

const description = css`
  font-size: 14px;
  color: ${colors.gray4};
`

const uniqueAndDefaultContainer = css`
  display: flex;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    flex-direction: column;
  }
`

export const FeePaxType = memo(({
  activityMinAge = 1,
  feePrice = 0,
  feePaxTypesErrors = [],
  control,
  register,
  feePaxTypes
}) => {
  const paxTypes = useMemo(() => paxTypesList(activityMinAge), [activityMinAge])

  const uniqueFee = useMemo(() => paxTypes.filter(({ name }) => UNIQUE_FEE_FILTER.includes(name)), [paxTypes])
  const defaultFee = useMemo(() => paxTypes.filter(({ name }) => DEFAULT_FEE_FILTER.includes(name)), [paxTypes])
  const aditionalFee = useMemo(() => paxTypes.filter(({ name }) => ADITIONAL_FEE_FILTER.includes(name)), [paxTypes])

  const isUniqueActive = useMemo(
    () => feePaxTypes?.some(({ name }) => UNIQUE_FEE_FILTER.includes(name)), [feePaxTypes]
  )
  const isAditionalActive = useMemo(
    () => feePaxTypes?.some(({ name }) => ADITIONAL_FEE_FILTER.includes(name)), [feePaxTypes]
  )
  const isDefaultActive = useMemo(
    () => feePaxTypes?.some(({ name }) => DEFAULT_FEE_FILTER.includes(name)), [feePaxTypes]
  )

  return (
    <>
      <H3 css={marginBottom10}>Tipificação de PAX</H3>
      <P css={[marginBottom20, description]}>A seleção de Tarifa única ou Multi Vagas implica no bloqueio das tarifas de adulto, criança e sênior.</P>
      <div css={[marginBottom20, uniqueAndDefaultContainer]}>
        <FeePaxDetails
          title='Tarifário único'
          isActive={isUniqueActive}
          feeType={uniqueFee}
          feePrice={feePrice}
          feePaxTypesErrors={feePaxTypesErrors}
          control={control}
          register={register}
          feePaxTypes={feePaxTypes}
        />
        <FeePaxDetails
          title='Tarifário padrão'
          isActive={isDefaultActive}
          feeType={defaultFee}
          feePrice={feePrice}
          feePaxTypesErrors={feePaxTypesErrors}
          control={control}
          register={register}
          feePaxTypes={feePaxTypes}
        />
      </div>
      <FeePaxDetails
        title='Tarifas adicionais'
        isActive={isAditionalActive}
        feeType={aditionalFee}
        feePrice={feePrice}
        feePaxTypesErrors={feePaxTypesErrors}
        control={control}
        register={register}
        feePaxTypes={feePaxTypes}
      />
    </>
  )
})
