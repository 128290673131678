import { css } from '@emotion/core'

export const summaryContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &.disable-white-space {
    white-space: initial;
  }
`

export const vacancyTableBodyCell = css`
  padding: 0.5rem;
`
