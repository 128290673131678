import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'

export const affiliatesListContainer = css`
  max-height: 25rem;
  min-height: 7.5rem;
  overflow-y: auto;
  margin-top: 0.5rem;
`

export const affiliatesListItem = css`
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: var(--round-element-radius, 100%);
  &:nth-child(2n) {
    background-color: ${colors.gray14};
  }
  &:hover {
    background-color: ${colors.gray10};
  }
  > div {
    padding: 1rem;
    display: flex;
    place-items: center;
    justify-content: space-between;
    overflow-x: auto;
    p {
      color: ${colors.gray2};
      &:first-of-type {
        font-size: 0.875rem;
        font-weight: bold;

        white-space: pre-line;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 1.25rem;
        word-wrap: break-word;

        max-lines: 2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &:last-of-type {
        margin-top: 0.15rem;
        font-size: 0.715rem;
      }
    }
  }
`

export const wrapper = css`
  margin-top: 2.188rem;
`

export const cardWrapper = css`
  padding: 0.625rem 1.25rem 0;
  display: flex;
  align-content: bottom;
  gap: 1.25rem;
  &.disabled {
    background-color: ${colors.gray14};
    .select-affiliate-btn {
      &, * { cursor: default !important; }
    }
  }
`

export const littleInput = css`
  width: 10.625rem;
`

export const marginBottom20 = marginBottom(20)

export const addAffiliateBtnStyle = css`
  margin-left: 0.35rem;
  height: 1.35rem;
  padding: 0 0 0 0;
  background-color: ${colors.gray5};

  border-radius: 0.5rem;
  > button {
    background-color: ${colors.indigo2};
    width: 1.50rem;
    box-shadow: none;
    height: 100%;
    border-radius: 0.5rem;
  }
  span {
    display: inline-block;
    transition: width 200ms ease;
    width: 0px;
    &::after {
      white-space: pre;
      content: " Afiliados";
      visibility: hidden;
      transition: visibility 200ms;
    }
  }

  &:hover {
    padding: 0 0.45rem 0 0;
    span {
      width: 4.25rem;
      &:after {
        visibility: visible;
      }
    }
  }

  &:disabled {
    &:hover {
      padding: 0;
      span {
        width: 0;
      }
    }
  }
`

export const observerDivStyle = css`
  &:after {
    content: " ";
    white-space: pre;
  }
`

export const notFoundStyle = css`
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.gray2};
`

export const centralizedDiv = css`
  display: flex;
  place-content: center;
  place-items: center;
  flex-direction: column;
`

export const iconSize = css`
  font-size: 1.25rem;
`

export const fullWidth = css`
  width: 100%;
`

export const label = css`
  font-size: 1rem;
  color: ${colors.gray2};
  margin: 0.625rem 0;
`

export const affiliateDialogStyle = css`
  width: 100%;
  max-width: 37.5rem;
`

export const paddingOnTopAndBottom = css`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

export const wrapperInput = css`
  display: flex;
  flex-direction: column;
`
