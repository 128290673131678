/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { formatMoney, head, identity, unformatMoney } from '@bonitour/common-functions'
import { TableContainer, TableHeader, TableHeaderCell, Checkbox, TableHeaderRow, TableBodyRow, TableBodyCell, TableBody, InputMoneyMask, TruncateCell } from '@bonitour/components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { popoverPositionStyle, moneyInput, valueWithPercentage } from './DetailTicketList.style'
import { TicketDetail } from '../TicketDetail/TicketDetail'

const DetailTicketListHeader = ({
  isSelectable = true,
  areTicketsSelected = false,
  isRefund = false,
  onSelectAll = identity
}) => (
  <TableHeader>
    <TableHeaderRow>
      {
        isSelectable &&
          <TableHeaderCell>
            <Checkbox
              checked={areTicketsSelected}
              onChange={identity}
              onClick={onSelectAll}
            />
          </TableHeaderCell>
      }
      <TableHeaderCell>Código</TableHeaderCell>
      <TableHeaderCell>Tipo</TableHeaderCell>
      <TableHeaderCell>Viajante</TableHeaderCell>
      {!isRefund && (
        <TableHeaderCell>
          Empresa
        </TableHeaderCell>
      )}
      <TableHeaderCell>Experiência</TableHeaderCell>
      <TableHeaderCell>{isRefund
        ? <>Valor<br/>recebido</>
        : <>Valor do<br/>ingresso</>
      }</TableHeaderCell>
      <TableHeaderCell>Valor a {isRefund ? 'reembolsar' : 'pagar'}</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
)

const DetailTicketListRow = ({
  ticketsIds = [],
  onTicketChange = identity,
  ticket,
  isSelectable = true,
  isRefund = false,
  isRefundSplit = false
}) => {
  const {
    id,
    company,
    activity,
    value,
    code,
    passagerName,
    payerName,
    type,
    ticketPaymentBalance,
    sentLinksValue: ticketSentLinksValue
  } = ticket || {}
  const remainingTicketValue = useMemo(() => head(ticketPaymentBalance)?.remainingTicketValue, [ticketPaymentBalance])
  const refundableTicketValue = useMemo(() => head(ticketPaymentBalance)?.refundableTicketValue, [ticketPaymentBalance])
  const ticketPrice = useMemo(() => head(ticketPaymentBalance)?.ticketPrice, [ticketPaymentBalance])
  const defaultValue = useMemo(() => isRefund ? refundableTicketValue : remainingTicketValue, [ticketPaymentBalance])
  const isSelected = useMemo(() => ticketsIds.find(ticket => ticket.id === id), [ticketsIds, id])
  const alreadyPaidTicketValue = useMemo(() => head(ticketPaymentBalance)?.alreadyPaidTicketValue, [ticketPaymentBalance])
  const historic = useMemo(() => head(ticketPaymentBalance)?.historic, [ticketPaymentBalance])
  const [valueToPay, setvalueToPay] = useState(defaultValue)
  const valueToPayNumber = useMemo(() => typeof valueToPay === 'number' ? valueToPay : unformatMoney(valueToPay), [valueToPay])
  const hasHistoric = useMemo(() => historic?.length > 0, [historic])
  const ticketSelectedValue = useMemo(() => ticketsIds.find(ticket => ticket.id === id)?.amount, [ticketsIds, id])
  const hasError = useMemo(() => isSelected && !valueToPayNumber > 0, [isSelected, valueToPay])

  const onCheckboxClick = () => {
    if (isSelected) {
      onTicketChange(ticketsIds.filter(ticket => ticket.id !== id))
    } else {
      onTicketChange(ticketsIds.concat({ ...ticket, amount: valueToPayNumber }))
    }
  }

  useEffect(() => {
    const updatedData = ticketsIds.map(ticket => (ticket.id === id ? { ...ticket, amount: valueToPayNumber, price: value } : ticket))
    onTicketChange(updatedData)
  }, [valueToPay])

  useEffect(() => {
    if (ticketSelectedValue) {
      setvalueToPay(ticketSelectedValue)
    }
  }, [ticketSelectedValue])

  const isDisabledInputMoneyMask = useMemo(
    () => isRefundSplit || !isSelected,
    [isSelected, isRefundSplit]
  )

  const handleOnChangeTicketValue = useCallback((value) => {
    const amountValue = unformatMoney(value)
    const maximumValue = isRefund ? refundableTicketValue : remainingTicketValue

    setvalueToPay(amountValue >= maximumValue ? formatMoney(maximumValue) : value)
  }, [])

  return (
    <TableBodyRow>
      {isSelectable &&
        <TableBodyCell>
          <Checkbox
            checked={isSelected}
            onChange={identity}
            onClick={onCheckboxClick}
          />
        </TableBodyCell>}
      <TruncateCell text={code} size='120px' />
      <TableBodyCell>{type}</TableBodyCell>
      <TruncateCell text={passagerName || payerName} size='100px' />
      {!isRefund && (
        <TruncateCell text={company} size='100px' />
      )}
      <TruncateCell text={activity} size='100px' />
      <TableBodyCell>
        {hasHistoric || ticketSentLinksValue
          ? (
            <TicketDetail
              value={value}
              ticketPrice={ticketPrice}
              sentLinksValue={ticket?.sentLinksValue}
              alreadyPaidTicketValue={alreadyPaidTicketValue}
              remainingTicketValue={remainingTicketValue}
              historic={historic}
              popoverPositionStyle={popoverPositionStyle}
              isRefund={isRefund}
            />)
          : formatMoney(ticketPrice)}
      </TableBodyCell>
      <TableBodyCell css={valueWithPercentage}>
        <InputMoneyMask
          error={hasError}
          css={moneyInput}
          value={valueToPay}
          onChange={handleOnChangeTicketValue}
          disabled={isDisabledInputMoneyMask}
        />
        {isSelected && !isRefund
          ? (
            <p>({Math.round((valueToPayNumber / remainingTicketValue) * 10000) / 100} %)</p>
          )
          : (
            null
          )
        }
      </TableBodyCell>
    </TableBodyRow>
  )
}

export const DetailTicketList = ({
  isSelectable = true,
  isRefund = false,
  isRefundSplit = false,
  loading = false,
  tickets = [],
  ticketsIds = [],
  onTicketIdsChange = identity
}) => {
  const areAllTicketsSelected = tickets.length && ticketsIds.length === tickets.length

  const hasStatus = Boolean(tickets.length) && Boolean(tickets[0].status)

  const ticketsIdsLastAmount = useMemo(() => ticketsIds.reduce((acc, ticket) => {
    acc[ticket.id] = ticket.amount
    return acc
  }, {}), [ticketsIds])

  const onSelectAll = useCallback(() => {
    if (areAllTicketsSelected) {
      onTicketIdsChange([])
    } else {
      onTicketIdsChange(tickets.map(ticket => ({
        ...ticket,
        amount: ticketsIdsLastAmount[ticket.id] || isRefund
          ? head(ticket.ticketPaymentBalance)?.refundableTicketValue
          : head(ticket.ticketPaymentBalance)?.remainingTicketValue
      })))
    }
  }, [areAllTicketsSelected, onTicketIdsChange, tickets, isRefund])

  return (
    <TableContainer>
      <DetailTicketListHeader
        isSelectable={isSelectable}
        areTicketsSelected={areAllTicketsSelected}
        onSelectAll={onSelectAll}
        hasStatus={hasStatus}
        isRefund={isRefund}
      />
      <TableBody loading={loading}>
        {
          tickets.map(ticket => (
            <DetailTicketListRow
              isSelectable={isSelectable}
              key={ticket.id}
              ticket={ticket}
              ticketsIds={ticketsIds}
              onTicketChange={onTicketIdsChange}
              hasStatus={hasStatus}
              isRefund={isRefund}
              isRefundSplit={isRefundSplit}
            />
          ))
        }
      </TableBody>
    </TableContainer>
  )
}
