/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core'

import { useActivity } from 'contexts/Activity'
import { colors, flexCenteredSpaceBetweenRow, flexRowCentered, GhostButton, H1, Modal } from '@bonitour/components'
import { margin, marginTop } from 'assets/styles/global'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import VacancyTable from './Table/VacancyTable'
import { BookingMapButtons } from './Structure/BookingMapButtons'

import { useOffsetHook } from './hooks/useOffsetHook'
import { useBookingMapDateTime } from './hooks/useBookingMapDateTime'
import { useDateColumns } from './hooks/useDateColumns'
import { useMapSubscriber } from './hooks/useMapSubscriber'
import { useMapDetailSubscriber } from './hooks/useMapDetailSubscriber'
import { identity } from '@bonitour/common-functions'
import { BookingLinkageGuide } from './Table/Guides/BookingLinkageGuide'
import { useCallback, useMemo } from 'react'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { ServiceCombinedExperienceList } from 'domains/CombinedExperience/components'
import { BookingMapGuide } from './Structure/BookingMapGuide'
import { useBookingMapInfo } from './contexts/useBookingMapInfo'

const noMargin = margin(0, 0, 0, 0)

const marginTop20 = marginTop(20)

const title = css`
  align-items: flex-end;
  margin-top: -50px;
`

const vacancyTableContainer = css`
  padding: 30px;
  margin: 20px 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.white};
`

const selectorPadding = css`
  padding-left: 15px;
`

export const BookingMapModal = ({
  date = '',
  isVisible = false,
  onModalClose = identity,
  reservationCode = ''
}) => {
  const { id: serviceId, activity = {} } = useActivity()
  const {
    experiences = []
  } = activity

  const {
    loadFeePrices,
    resetForm
  } = useBookingMapInfo()

  const isCombinedExperience = useMemo(() => activity?.type === COMBINED_EXPERIENCE_TYPE, [activity])

  const onCloseClick = useCallback(() => {
    if (isCombinedExperience) {
      resetForm()
    }
    onModalClose()
  }, [isCombinedExperience, onModalClose, resetForm])

  const [tableReference, offset] = useOffsetHook(isVisible)
  const { selectedHour, referenceDate, changeHour, changeToPrevious, changeToNext } = useBookingMapDateTime(offset, date || '')
  const dateColumns = useDateColumns(referenceDate, offset)
  const { registries, prices, activitiesRegistryAndPrice, isLoading, activitiesColors, debouncedDates } = useMapSubscriber(dateColumns)
  const tableDetailedData = useMapDetailSubscriber(activity, dateColumns, selectedHour)
  const GuideComponent = useMemo(() => BookingLinkageGuide(serviceId, reservationCode), [serviceId, reservationCode])

  loadFeePrices({ initialFeePrices: activitiesRegistryAndPrice })

  return (
    <Modal isVisible={isVisible} onCloseClick={onCloseClick}>
      <div css={[flexCenteredSpaceBetweenRow]}>
        <div css={[flexRowCentered, title]}>
          <H1 css={noMargin}>Vincular</H1>
          <ActivitySelector
            css={selectorPadding}
            subdomain={null}
            onChangeActivity={isCombinedExperience ? resetForm : identity}
            disableOffline
          />
          <H1 css={noMargin}>na reserva {reservationCode}</H1>
        </div>
      </div>

      {isCombinedExperience
        ? (
          <ServiceCombinedExperienceList experiences={experiences} customCss={[marginTop20]} canShowTotals={true} />
        )
        : null}

      <div css={vacancyTableContainer} ref={tableReference}>
        <BookingMapButtons
          onClickPrevious={changeToPrevious}
          onNextClick={changeToNext}
          offset={offset}
        />
        <VacancyTable
          isExpandable={false}
          date={referenceDate}
          dateColumns={dateColumns}
          debouncedDateColumns={debouncedDates}
          tableData={registries}
          tablePrices={prices}
          tableDetailedData={tableDetailedData}
          currentExpandedRowLabel={selectedHour}
          VacancySummaryPopoverContent={GuideComponent}
          onExpand={changeHour}
          activitiesRegistryAndPrice={activitiesRegistryAndPrice}
          isCombinedExperience={isCombinedExperience}
          isLoading={isLoading}
        />
        {isCombinedExperience ? <BookingMapGuide activitiesColors={activitiesColors} isCombinedExperience={true} /> : ''}
      </div>
      <GhostButton onClick={onModalClose}>
        Cancelar
      </GhostButton>
    </Modal>
  )
}
