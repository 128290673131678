import { colors } from '@bonitour/components'

export const ticketStatusDictionary = {
  canceled: {
    label: 'Cancelado',
    color: colors.danger,
    isCancelable: false,
    isEditable: false,
    value: 'canceled'
  },
  reserved: {
    label: 'Reservado',
    color: colors.orange1,
    isValidatable: true,
    isEditable: true,
    isCancelable: true,
    value: 'reserved'
  },
  'no-show': {
    label: 'Não compareceu',
    color: colors.gray5,
    isCancelable: false,
    isEditable: false,
    value: 'no-show'
  },
  confirmed: {
    label: 'Confirmado',
    color: colors.green1,
    isCancelableOnlyAsProvider: true,
    isValidatable: true,
    isEditable: true,
    value: 'confirmed'
  },
  used: {
    label: 'Usado',
    color: colors.green1,
    isCancelableOnlyAsProvider: true,
    isEditable: false,
    value: 'used'
  },
  processing: {
    label: 'Processando',
    color: colors.orange1,
    isCancelable: false,
    isEditable: false,
    value: 'processing'
  },
  checked_in: {
    label: 'Check-in',
    color: colors.green1,
    isCancelableOnlyAsProvider: true,
    isEditable: false,
    value: 'checked_in'
  },
  partially_paid: {
    label: 'Parcialmente pago',
    color: colors.orange1,
    isCancelable: false,
    isEditable: false,
    value: 'partially_paid'
  },
  not_paid: {
    label: 'Não pago',
    color: colors.orange1,
    isCancelable: true,
    isEditable: true,
    value: 'not_paid'
  }
}

export const editableTicketStatus = Object
  .entries(ticketStatusDictionary)
  .reduce((acc, [key, { isEditable }]) => {
    if (isEditable) {
      return acc.concat(key)
    }
    return acc
  }, [])

export const ticketStatusOptions = [
  {
    label: 'Reservado',
    value: 'reserved'
  },
  {
    label: 'Não compareceu',
    value: 'no-show'
  },
  {
    label: 'Confirmado',
    value: 'confirmed'
  },
  {
    label: 'Usado',
    value: 'used'
  }
]

export const ticketTypeEnum = Object.freeze({
  offline: 'offline'
})
