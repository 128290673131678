/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker, MultiSelectDropdown } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string, array } from 'yup'
import { useEffect } from 'react'
import { saleStatusOptions } from './../constants/saleStatus'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { useKeyPress } from 'hooks/useKeyPress'
import { pointer } from 'domains/Notice/Form/NoticeForm.style'
import { useListEmployees } from 'domains/Cashier/hooks/useListEmployees'
import { sellingChannelOptions } from '../constants/sellingChannel'
import { useVendorsList } from 'hooks/domains/useVendorList'
import { useSellersList } from 'hooks/domains/useSellersList'
import { usePartnerActivityOptions } from 'domains/CompanyReports/hooks/usePartnerActivityOptions'
import { useLocalitiesForm } from 'hooks/domains/useLocalitiesForm'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`
export const multiSelect = css`
  width: 100%;
  ${pointer};
`

export const SalesFilterSchema = {
  status: array().of(string()).nullable(),
  seller: array().of(string()).nullable(),
  vendor: array().of(string()).nullable(),
  activity: array().of(string()).nullable(),
  sellingChannel: string(),
  countries: array().of(string()),
  states: array().of(string()),
  cities: array().of(string())
}

export const SalesFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {}
}) => {
  const { add: addToast } = useToast()
  const { vendorsOptions } = useVendorsList(false)
  const { sellersOptions } = useSellersList(false)
  const { activityOptions, getActivityOptions } = usePartnerActivityOptions(false)

  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, SalesFilterSchema)
  const { saleDateStart, saleDateEnd, tourDateStart, tourDateEnd, status = [], seller = [], vendor = [], activity = [], sellingChannel = '', userId = '' } = form
  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  const {
    onChangeCities = identity,
    onChangeCountries = identity,
    onChangeStates = identity,
    placeholders: localitiesPlaceholders,
    options: localitiesOptions,
    isEmptyCitiesData,
    isEmptyStatesData,
    isSelectedManyCountries,
    isSelectedManyStates,
    isLoading: {
      countries: isLoadingCountries,
      states: isLoadingStates,
      cities: isLoadingCities
    }
  } = useLocalitiesForm({
    form,
    onInputChange
  })

  useEffect(() => {
    getActivityOptions(vendor)
    // eslint-disable-next-line
  }, [vendor])

  // Remove selected activities that are not in the options list
  useEffect(() => {
    if (!activityOptions?.length) return
    const validActivities = (activity || [])
      .filter(activity => activityOptions.find(({ value }) => value === activity))
    if (validActivities.length !== activity.length) {
      onInputChange('activity')(validActivities)
    }
  }, [activity, activityOptions, onInputChange])

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(saleDateStart, saleDateEnd) && validateDateRange(tourDateStart, tourDateEnd) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  const { filterEmployeesOptions, loading: loadingEmployees } = useListEmployees()

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial de venda' errorMessage={errors.saleDateStart}>
            <DatePicker
              value={saleDateStart}
              onChange={onInputChange('saleDateStart')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final de venda' errorMessage={errors.saleDateEnd}>
            <DatePicker
              value={saleDateEnd}
              onChange={onInputChange('saleDateEnd')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial da experiência' errorMessage={errors.tourDateStart}>
            <DatePicker
              value={tourDateStart}
              onChange={onInputChange('tourDateStart')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final da experiência' errorMessage={errors.tourDateEnd}>
            <DatePicker
              value={tourDateEnd}
              onChange={onInputChange('tourDateEnd')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <MultiSelectDropdown
            label='Vendedor'
            placeholder='Todos'
            errorMessage={errors.seller}
            selected={seller}
            onChange={onInputChange('seller')}
            options={sellersOptions}
            customCss={[multiSelect]}
          />
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <MultiSelectDropdown
            label='Fornecedor'
            placeholder='Todos'
            errorMessage={errors.vendor}
            selected={vendor}
            onChange={onInputChange('vendor')}
            options={vendorsOptions}
            customCss={[multiSelect]}
          />
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <MultiSelectDropdown
            label='Experiência'
            placeholder='Todos'
            errorMessage={errors.activity}
            selected={activity}
            onChange={onInputChange('activity')}
            options={activityOptions}
            customCss={[multiSelect]}
          />
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <MultiSelectDropdown
            label='Status'
            placeholder='Todos'
            errorMessage={errors.status}
            selected={status}
            onChange={onInputChange('status')}
            options={saleStatusOptions}
            customCss={[multiSelect]}
          />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label="País" errorMessage={errors.countries}>
            <MultiSelectDropdown
              options={localitiesOptions.countriesOptions}
              selected={form.countries}
              onChange={onChangeCountries}
              placeholder={localitiesPlaceholders.countries}
              isLoading={isLoadingCountries}
              isDisableSelectAll={true}
              disabled={false}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label="Estado" errorMessage={errors.states}>
            <MultiSelectDropdown
              options={localitiesOptions.statesOptions}
              selected={form.states}
              onChange={onChangeStates}
              disabled={isEmptyStatesData || isLoadingStates || isSelectedManyCountries}
              placeholder={localitiesPlaceholders.states}
              isDisableSelectAll={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label="Cidade" errorMessage={errors.cities}>
            <MultiSelectDropdown
              options={localitiesOptions.citiesOptions}
              selected={form.cities}
              onChange={onChangeCities}
              disabled={isEmptyCitiesData || isLoadingCities || isSelectedManyStates || isSelectedManyCountries}
              placeholder={localitiesPlaceholders.cities}
              isDisableSelectAll={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Origem' errorMessage={errors.sellingChannel}>
            <Select
              placeholder='Todos'
              options={sellingChannelOptions}
              value={sellingChannel}
              error={errors.sellingChannel}
              onChange={onInputChange('sellingChannel')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Agente responsável' errorMessage={errors.userId}>
            <Select
              placeholder='Todos'
              options={filterEmployeesOptions}
              disabled={loadingEmployees}
              value={userId}
              error={errors.userId}
              onChange={onInputChange('userId')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}
