/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { GhostButton, useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { string, object } from 'yup'
import { ReservationService } from 'services/Reservations/Service'
import { Affiliate } from 'containers/Booking/Form/Affiliate/Affiliate'
import { ghostButtonPrimary } from './EditReservation.style'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { hidden } from 'assets/styles/global'
import { StatusEnum } from 'constants/reservationsStatus'
import { useCompany } from 'contexts/Company'

const buttonsWrapper = css`
  padding-top: 0.15rem;

  button {
    width: 10.625rem;
    &:disabled {
      opacity: 0.3;
    }
  }

  &.row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`

const customCard = css`
  padding-bottom: 1rem !important;
  flex-direction: column;

  label {
    width: fit-content;
  }

  [class*="Row"] {
    margin-bottom: 0;
    margin-top: 0;
    > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const defaultFormEvent = () => identity

const blockedAffiliateEditionStatuses = [StatusEnum.confirmed, StatusEnum.used, StatusEnum.noShow]
const allowedStatuses = ['reserved', 'confirmed', 'quotation']
const affiliateSchema = object().shape({
  affiliateCode: string().required('O código de afiliado é obrigatório')
})

export const EditAffiliate = ({
  affiliate = '',
  reservationId = '',
  reservation = {},
  onChange: emitChangeEvent = defaultFormEvent,
  fetchReservationTickets = identity,
  updateReservation = identity,
  fiscalDuty = '',
  reservationTickets = []
}) => {
  const { id: companyId } = useCompany()
  const { add: addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const hasFiscalDuty = useMemo(() => Boolean(fiscalDuty),
    [fiscalDuty])

  const isVendor = useMemo(() => companyId === reservation?.accountableId, [companyId, reservation?.accountableId])

  const hasConfirmedTicket = useMemo(() => (reservationTickets || []).some(([_date, activities]) => {
    return activities
      .some((currentActivity) => currentActivity.tickets
        .some(({ state: ticketStatus }) => blockedAffiliateEditionStatuses.includes(ticketStatus)))
  }), [reservationTickets])

  const canModifyAffiliate = useMemo(() => {
    const reservationStatus = reservation?.status
    const isAllowedReservationStatus = allowedStatuses.includes(reservationStatus)

    return isAllowedReservationStatus && !hasConfirmedTicket && !hasFiscalDuty && !affiliate && isVendor
  }, [reservation?.status, hasConfirmedTicket, hasFiscalDuty, affiliate, isVendor])

  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    register
  } = useForm({
    resolver: yupResolver(affiliateSchema, {
      abortEarly: false,
      stripUnknown: true
    }),
    defaultValues: {
      affiliateCode: affiliate
    }
  })

  const affiliateCodeValue = watch('affiliateCode')

  const onCancelEdit = useCallback(() => {
    setIsEditing(false)
    setValue('affiliateCode', '')
  }, [setValue])

  const onSelectAffiliateCode = useCallback((value) => {
    setValue('affiliateCode', value)
  }, [setValue])

  const cancel = useCallback((_error) => {
    setValue('affiliateCode', affiliate || '')
  }, [affiliate, setValue])

  const save = useCallback((form) => {
    setIsLoading(true)
    ReservationService.addAffiliate(reservationId, form.affiliateCode)
      .then(() => {
        setIsEditing(false)
        emitChangeEvent('affiliateCode')(form.affiliateCode)
        addToast('Afiliado adicionado à reserva', 'success')
        updateReservation()
      })
      .catch((err) => {
        const message = err?.data?.errors_msg?.includes('account::affiliate::not_found')
          ? 'Código de afiliado não encontrado'
          : 'Erro ao adicionar afiliado'
        addToast(message, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [addToast, emitChangeEvent, reservationId, updateReservation])

  useEffect(() => {
    setValue('affiliateCode', affiliate)
  }, [affiliate, setValue])

  useEffect(() => {
    fetchReservationTickets()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Affiliate
      affiliate={affiliateCodeValue}
      affiliateErrors={errors?.affiliateCode?.message}
      isDisabled={!canModifyAffiliate || isLoading || !isEditing}
      customCardCss={[customCard]}
      fieldRegister={register}
      onSelectAffiliate={onSelectAffiliateCode}
    >
      {isEditing
        ? (
          <div css={buttonsWrapper} className={'row'}>
            <GhostButton onClick={onCancelEdit} disabled={isLoading}>
                    Cancelar
            </GhostButton>
            <GhostButton
              onClick={handleSubmit(save, cancel)}
              css={ghostButtonPrimary(!canModifyAffiliate || isLoading || !affiliateCodeValue)}
              disabled={!canModifyAffiliate || isLoading || !affiliateCodeValue}
            >
                    Adicionar
            </GhostButton>
          </div>
        )
        : null}
      <div css={[buttonsWrapper, !(!isEditing && canModifyAffiliate) && hidden]}>
        <GhostButton
          onClick={() => setIsEditing(true)}
          disabled={isLoading}
          css={ghostButtonPrimary(!canModifyAffiliate)}>
                  Preencher
        </GhostButton>
      </div>
    </Affiliate>
  )
}
