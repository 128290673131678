import { effect, signal } from '@preact/signals-core'

export const isScrolling = signal(false)
const timeout = signal(null)

effect(() => {
  if (isScrolling.value) {
    if (timeout.value) {
      clearTimeout(timeout.value)
    }
    setTimeout(() => {
      isScrolling.value = false
    }, 100)
  }
})
