import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { pick } from 'utils/object'

export const useListSales = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [salesData, setSalesData] = useState({ sales: undefined, meta: undefined, totals: undefined })

  const fetchSales = useCallback(({ filters, pagination }) => {
    const datesFilters = pick(filters, ['saleDateEnd', 'saleDateStart', 'tourDateEnd', 'tourDateStart'])

    if (!Object.values(datesFilters).some(Boolean)) {
      return addToast('É necessário preencher ao menos um período de tempo', 'warning')
    }
    setLoading(true)

    return ReportService.listSales({ filters, pagination }).then(setSalesData)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao relatório de vendas')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportSalesReport = useCallback((columnFilters, filters) => {
    setExporting(true)
    const csvFilters = { ...columnFilters, ...filters }
    return ReportService.csvExport({ filters: csvFilters })
      .then(() => {
        addToast('Relatório de vendas enviado ao seu e-mail!', 'success')
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao relatório de vendas')
        hasPermission && addToast('Ocorreu um erro ao exportar o relatório')
      })
      .finally(() => setExporting(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterSubmit = filters => setFilters(filters)

  const changePagination = useCallback((currentPage, perPage) => {
    const params = { currentPage, perPage }
    fetchSales({ filters, pagination: params })
    // eslint-disable-next-line
  }, [filters])

  return {
    filters,
    setFilters,
    onFilterSubmit,
    changePagination,
    sales: salesData?.sales,
    meta: salesData?.meta,
    totals: salesData?.totals,
    loading,
    exportSalesReport,
    exporting
  }
}
