import React, { useMemo } from 'react'
import xhook from 'xhook'
import { createInstance, OptimizelyProvider, useDecision } from '@optimizely/react-sdk'
import { useUser } from './User'
import { useCompany } from './Company'

const { REACT_APP_OPTIMIZELY_KEY, REACT_APP_OPTIMIZELY_CDN_PROXY } = process.env

xhook.before((request) => {
  if (REACT_APP_OPTIMIZELY_CDN_PROXY) {
    request.url = request.url.replace('https://cdn.optimizely.com/', REACT_APP_OPTIMIZELY_CDN_PROXY)
  }
})

const optimizely = createInstance({ sdkKey: REACT_APP_OPTIMIZELY_KEY })

const companyValidation = ({
  currentCompany,
  currentUser,
  enabled,
  variables,
  otherValidation
}) => {
  const flagCompanyWhitelist =
    variables?.allowedCompanies?.companies ||
    variables?.allowed?.companies

  const isCompanyEnabled =
    enabled &&
    (!flagCompanyWhitelist ||
      !currentCompany ||
      flagCompanyWhitelist.includes(currentCompany))

  const flagUserWhitelist = variables?.allowed?.users?.[
    currentCompany || ''
  ]

  const isUserEnabled =
    enabled &&
    (!flagUserWhitelist ||
      !currentUser ||
      flagUserWhitelist.includes(currentUser))

  if (otherValidation) {
    return otherValidation(isCompanyEnabled && isUserEnabled, variables)
  }
  return isCompanyEnabled && isUserEnabled
}

export const Feature = (props) => {
  const { user } = useUser()

  const userInfo = useMemo(
    () => {
      const { id, ...attributes } = user
      return {
        id,
        attributes
      }
    }
    , [user]
  )

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={userInfo}
      {...props}
    />
  )
}

export const useFeatureFlag = (featureKey) => {
  const [feature, clientReady, timedOut] = useDecision(featureKey, { autoUpdate: true })

  const { enabled, variables } = feature || {}

  const { user } = useUser()
  const { company } = useCompany()

  const enabledWithValidation = companyValidation({
    currentCompany: company?.id,
    currentUser: user?.id,
    enabled,
    variables
  })

  return [enabledWithValidation, variables, clientReady, timedOut]
}
