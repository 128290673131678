/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { serviceCombinedExperienceCardImage, serviceCombinedExperienceCardItem, serviceCombinedExperienceTitle } from '../ServiceCombinedExperienceList.style'
import { SwatchImage } from 'components/SwatchImage'

export const ServiceCombinedExperienceHeader = ({
  experienceInfo = {},
  index = 0,
  color = null
}) => {
  const { serviceImage, serviceTitle } = experienceInfo
  return (
    <div css={serviceCombinedExperienceCardItem(index, color)}>
      <SwatchImage
        css={serviceCombinedExperienceCardImage}
        image={serviceImage}
        alternativeText={serviceTitle}
      />
      <h3 css={serviceCombinedExperienceTitle(index, color)}>{serviceTitle}</h3>
    </div>
  )
}
