/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, memo } from 'react'

import { CombinedExperienceSummaryCellGroup } from '../CombinedExperienceSummaryCellGroup'
import { BlockedSummaryCell } from '../../../BodyCell/BlockedSummaryCell'

import { flexColumn, increasedWidth, padding0, w100 } from './combinedExperienceSummary.styles'
import { identity } from '@bonitour/common-functions'

const ExperienceSummary = ({
  onSelectActivity = identity,
  activitiesData = [],
  dateColumns = [],
  date = '',
  activitiesFees = [],
  selectedActivities = []
}) => {
  const getCanSetStaticWidth = useCallback((currentActivityId) => {
    if (activitiesData.length === 1) return true
    for (const activityData of activitiesData) {
      if (activityData.activityId !== currentActivityId && Boolean(activityData.slots?.[date])) {
        return false
      }
    }
    return true
  }, [activitiesData, date])

  const getBlockedSummaryAttrs = useCallback((activityData) => {
    const canShowBlockedSummary = !activityData || !activityData?.vacancies?.isAvailable

    const isEnabledStaticSummaryWidth = canShowBlockedSummary && getCanSetStaticWidth(activityData?.activityId)

    return [canShowBlockedSummary, isEnabledStaticSummaryWidth]
  }, [getCanSetStaticWidth])

  const getSelectedTotals = useCallback((activityId, hour, date) => {
    const quantityTotals = selectedActivities
      .reduce((acc, curr) => (curr.serviceId === activityId && curr.date === date && curr.hour === hour) ? acc + curr.quantity : acc, 0)

    return quantityTotals
  }, [selectedActivities])

  return (
    <div css={[flexColumn, increasedWidth]}>
      {activitiesData.map((activityData, activityIndex) => (
        <CombinedExperienceSummaryCellGroup
          key={`experience-summary-${activityData.activityId}-${activityIndex}-${activityData.hour}`}
          getBlockedSummaryAttrs={getBlockedSummaryAttrs}
          getSelectedTotals={getSelectedTotals}
          onSelectActivity={onSelectActivity}
          dateColumns={dateColumns}
          date={date}
          activityData={activityData}
          activitiesFees={activitiesFees}
          activityIndex={activityIndex}
          limitOfDay={activityData.limitOfDay}
        >
          <BlockedSummaryCell
            key={`vacancy-unavailable-${activityData.activityId}-${activityIndex}-${activityData.hour}`}
            canMinify={true}
            getBlockedSummaryAttrs={getBlockedSummaryAttrs}
            customCss={[padding0, w100]}
          />
        </CombinedExperienceSummaryCellGroup>
      ))}
    </div>
  )
}

export const CombinedExperienceSummary = memo(ExperienceSummary)
