/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { marginBottom, width100 } from 'assets/styles/global'
import { InputMoneyMask, flexHorizontallyCentered, H3, Input, HorizontalInputFormGroup, BREAK_POINTS } from '@bonitour/components'
import { Controller } from 'react-hook-form'
import { memo } from 'react'

const marginBottom20 = marginBottom(20)

const valueStyle = css`
  margin-left: 25px;

@media (max-width: ${BREAK_POINTS.tableUp}) {
  margin-left: 0;
  margin-top: 10px;
}
`

const sameLineInput = css`
  margin-left: 10px;
`

const littleInput = css`
  width: 160px;
`

const flexColumnStart = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const FeeBasicForm = memo(({
  feeBasicErrors = {},
  register,
  control,
  onChangeBasePrice
}) => {
  return (
    <>
      <H3 css={marginBottom20}>Resumo</H3>
      <div css={[flexHorizontallyCentered, marginBottom20, flexColumnStart]}>
        <div css={[flexHorizontallyCentered]}>
          <p>Nome</p>
          <HorizontalInputFormGroup css={sameLineInput} errorMessage={feeBasicErrors?.name?.message}>
            <Input
              css={width100}
              onChangeWholeEvent={true}
              {...register('feeBasic.name')}
            />
          </HorizontalInputFormGroup>
        </div>

        <div css={[flexHorizontallyCentered, valueStyle]}>
          <p>Valor</p>
          <HorizontalInputFormGroup css={sameLineInput} errorMessage={feeBasicErrors.price?.message}>
            <Controller
              control={control}
              name={'feeBasic.price'}
              render={({ field: { value } }) => (
                <InputMoneyMask
                  css={littleInput}
                  onChange={onChangeBasePrice}
                  value={value}
                />
              )}
            />
          </HorizontalInputFormGroup>
        </div>
      </div>
    </>
  )
})
